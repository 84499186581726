import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckInput } from "src/modules/common/components/CheckInput";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import { competenciaCargoActions } from "../handlers/redux";
import { CompetenciaCargoTableAccionMenu } from "./CompetenciaCargoTableAccionMenu";
import { CompetenciaCargoToolbar } from "./CompetenciaCargoToolbar";
import { useNavigate } from "react-router-dom";

export const CompetenciaCargoTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // @ts-ignore
  const competenciaCargos = useSelector(
    (state) => state.competenciaCargo.competencia_cargos
  );
  const competenciaCargos_checked = useSelector(
    (state) => state.competenciaCargo.competencia_cargos_checked
  );
  const competencia_cargos_loading = useSelector(
    (state) => state.competenciaCargo.competencia_cargos_loading
  );
  const { results = [] } = competenciaCargos;

  const selectAllChange = (event) => {
    results.map((item, idx) =>
      dispatch(
        competenciaCargoActions.checkCompetenciaCargo({
          competenciaCargo: item,
          checked: event.target.checked,
        })
      )
    );
  };

  const renderTableHeader = () => {
    return (
      <div className="border-b flex flex-col border-zinc-100 ">
        <div className="flex flex-row items-center">
          <CheckInput
            checked={
              competenciaCargos_checked.length === results.length &&
              results.length > 0
            }
            onChange={selectAllChange}
          ></CheckInput>
          <div className="p-1 font-bold flex-grow">
            Lista de competencias creadas
          </div>
          <div className="p-1 font-bold text-center w-60  mr-4">Cargos</div>
          <div className=" w-6"></div>
        </div>
      </div>
    );
  };

  const goToDetail = (item) => {
    console.log("Ir a Detalle", item)
    let { cargos = [] } = item;
    dispatch(
      competenciaCargoActions.competenciaCargoSet({
        ...item,
        cargos: cargos.map((cargo) => cargo.id),
      })
    );
    dispatch(
      competenciaCargoActions.setCompetenciaCargoEditing(false)
    );
    navigate(`/competencias/cargo/${item.id}/view`);
  }

  const renderTableItem = (item, idx) => {
    return (
      <div
        key={idx}
        className="hover:bg-sky-100 flex flex-row border-b py-4 align-middle"
      >
        <div className="p-1 h-16 align-text-top  flex flex-row items-center">
          <CheckInput
            checked={competenciaCargos_checked.includes(item.id)}
            onChange={(event) => {
              dispatch(
                competenciaCargoActions.checkCompetenciaCargo({
                  competenciaCargo: item,
                  checked: event.target.checked,
                })
              );
            }}
          />
        </div>
        <div
          className="p-1 flex flex-grow flex-col justify-center cursor-pointer w-2/3"
          onClick={() => goToDetail(item)}>
          <div className="font-bold">{item.nombre}</div>
          <div className="text-sm">{item.descripcion}</div>
        </div>
        <div className="p-1 text-center w-48 flex flex-row justify-center items-center">
          {item.cargos.length}
        </div>
        <div className="p-1 w-12 flex flex-row items-center">
          <CompetenciaCargoTableAccionMenu item={item} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col w-full">
        {renderTableHeader()}
        <div className="flex flex-col">
          {!!competencia_cargos_loading &&
            [1, 2, 3].map((sl, idx) => (
              <SkeletonLoaderRow key={`sl-${idx}`}></SkeletonLoaderRow>
            ))}
          {!competencia_cargos_loading &&
            results.length > 0 &&
            results.map(renderTableItem)}
          {!competencia_cargos_loading && results.length < 1 && (
            <div className="flex h-20 items-center justify-center">
              <div>No existen registros para mostrar.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
