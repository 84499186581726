import React from "react";
import { CheckLg, PencilFill, XLg } from "react-bootstrap-icons";
const RangoNineboxRow = (props) => {
  const {
    rango,
    index,
    onEditRow,
    isEditable = false,
    isEditing = false,
    setIsEditing,
  } = props;

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSave = () => {
    setIsEditing(false);
  };

  const onCancel = () => {
    setIsEditing(false, true);
  };

  const onChange =
    (field) =>
    ({ target }) => {
      let value = target.value;
      let { nombre, puntaje_desde } = rango;
      if (field === "nombre") onEditRow(index, value, puntaje_desde);
      if (field === "puntaje_desde") onEditRow(index, nombre, value);
    };

  return (
    <div className="w-full justify-between flex border-2x border-red-500x">
      {!!isEditable && (
        <>
          <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
            {!isEditing && <div>{rango.nombre}</div>}
            {!!isEditing && (
              <div>
                <input
                  type="text"
                  className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
                  value={rango.nombre || ""}
                  onChange={onChange("nombre")}
                />
              </div>
            )}
            <div className="gap-1 mr-8 hidden group-hover:flex">
              {!isEditing && (
                <button className="border p-2 rounded-md" onClick={onEdit}>
                  <PencilFill />
                </button>
              )}
              {!!isEditing && (
                <>
                  <button
                    className="border p-2 rounded-md bg-sky-500"
                    onClick={onSave}
                  >
                    <CheckLg size={20} color={"white"}></CheckLg>
                  </button>
                  <button className="border p-2 rounded-md" onClick={onCancel}>
                    <XLg size={20}></XLg>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
            {/* Puntaje Desde */}
            <div className="flex-1">
              {!isEditing && <>{rango.puntaje_desde}%</>}
              {!!isEditing && (
                <input
                  type="text"
                  maxLength={4}
                  className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
                  value={rango.puntaje_desde}
                  onChange={onChange("puntaje_desde")}
                />
              )}
            </div>
            {/* Puntaje Hasta */}
            <div className="flex-1">
              <div className="flex items-center justify-between gap-2 w-1/2">
                <div>{rango.puntaje_hasta}%</div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* ReadOnly Row */}
      {!isEditable && (
        <>
          <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
            <div>{rango.nombre}</div>
          </div>
          <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
            <div className="flex-1">{rango.puntaje_desde}%</div>
            <div className="flex-1">{rango.puntaje_hasta}%</div>
          </div>
        </>
      )}
    </div>
  );
};

export default RangoNineboxRow;
