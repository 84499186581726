import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { competenciaPotencialApi } from "./apis";
import { competenciaPotencialActions } from "./redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";

function* getCompetenciasPotencialSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(competenciaPotencialApi(token).list);
    yield put(competenciaPotencialActions.setCompetenciasPotencial(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(competenciaPotencialActions.setCompetenciasPotencial([]));
    ErrorToast({ message: error.response.data.error });
  }
}

function* updateCompetenciasPotencialSaga({ payload }) {
  const token = getToken();
  const competencias_potencial_aux = yield select(
    (state) => state.competenciaPotencial.competencias_potencial_aux
  );
  const aplicar_pesos = yield select(
    (state) => state.competenciaPotencial.aplicar_pesos_potenciales
  );
  // Verificacion de Pesos
  const sumaPesos = competencias_potencial_aux.reduce(
    (sum, c) => sum + c.peso,
    0
  );
  // Verificacion de Pesos
  let competencias = competencias_potencial_aux;
  if (!aplicar_pesos) {
    competencias = competencias_potencial_aux.map((comp, idx) => ({
      ...comp,
      peso: 0,
    }));
  } else if (sumaPesos !== 100 && sumaPesos !== 0) {
    ErrorToast({
      message: "La suma de los pesos no son igual a 100%",
    });
    yield put(
      competenciaPotencialActions.setCompetenciasPotencialLoading(false)
    );
    return;
  }
  //
  try {
    const data = yield call(
      competenciaPotencialApi(token).updateCompetenciasPotencial,
      competencias
    );
    yield put(competenciaPotencialActions.setCompetenciasPotencial(data));
    yield put(competenciaPotencialActions.setCompetenciasPotencialEdit(false));
    SuccessToast({ message: "Competencias de Potencial actualizadas" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(competenciaPotencialActions.setCompetenciasPotencial([]));
    ErrorToast({ message: error.response.data.error });
  }
}

export function* competenciaPotencialSagas() {
  yield takeEvery(
    competenciaPotencialActions.getCompetenciasPotencial.type,
    getCompetenciasPotencialSaga
  );
  yield takeEvery(
    competenciaPotencialActions.updateCompetenciasPotenciales.type,
    updateCompetenciasPotencialSaga
  );
}
