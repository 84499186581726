import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ImageCropper from "src/modules/common/components/ImageCropper";
import ImageInput from "src/modules/common/components/ImageInput";
import ImageUploadAlt from "src/modules/common/components/ImageUploadAlt";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import SelectModal from "src/modules/common/components/SelectModal";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { commonActions } from "src/modules/common/handler/redux";
import { AreaSelect } from "src/modules/estructura/modules/area/components/AreaSelect";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { CargoSelect } from "src/modules/estructura/modules/cargo/components/CargoSelect";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import { DepartamentoSelect } from "src/modules/estructura/modules/departamento/components/DepartamentoSelect";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { SucursalSelect } from "src/modules/estructura/modules/sucursal/components/SucursalSelect";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import userDefault from "../../../images/user_default.png";
import { colaboradorActions } from "../handlers/redux";
import { X } from "react-bootstrap-icons";
import { utcToZonedTime } from "date-fns-tz";

function ColaboradorGeneralPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idColaborador } = params;
  const [showImageInput, setShowImageInput] = useState(false);
  const colaborador = useSelector((state) => state.colaborador.colaborador);
  const esModoVista = useSelector((state) => state.colaborador.esModoVista);
  const hasChanged = useSelector((state) => state.colaborador.hasChanged);
  const setEsModoVista = (esModoVista) => {
    dispatch(colaboradorActions.setEsModoVista(esModoVista));
  };
  const setHasChanged = (hasChanged) => {
    dispatch(colaboradorActions.setHasChanged(hasChanged));
  };
  const estadoCivilDict = {
    SO: "Soltero",
    CA: "Casado",
    DI: "Divorciado",
    UL: "Unión Libre",
  };

  const datosPersonalesInputs = [
    {
      name: "Nombre",
      field: "nombre",
      type: "text",
      required: true,
    },
    {
      name: "Apellido",
      field: "apellido",
      type: "text",
      required: true,
    },
    {
      name: "Número de ID",
      field: "ci",
      type: "text",
      required: true,
    },
    {
      name: "RUC",
      field: "ruc",
      type: "text",
    },
    {
      name: "Sexo",
      field: "sexo",
      type: "select",
      required: true,
      options: [
        { value: null, label: "Elija una opción" },
        { value: "M", label: "Masculino" },
        { value: "F", label: "Femenino" },
      ],
    },
    {
      name: "Estado civil",
      field: "estado_civil",
      type: "select",
      options: [
        { value: null, label: "Elija una opción" },
        { value: "SO", label: "Soltero" },
        { value: "CA", label: "Casado" },
        // { value: "DI", label: "Divorciado" },
        // { value: "UL", label: "Union libre" },
      ],
    },
    {
      name: "Correo",
      field: "email",
      type: "text",
      required: false,
    },
    {
      name: "Teléfono",
      field: "telefono",
      type: "text",
    },
    {
      name: "Celular",
      field: "celular",
      type: "text",
    },
    {
      name: "Domicilio",
      field: "domicilio",
      type: "text",
    },
    {
      name: "Ciudad",
      field: "ciudad_domicilio",
      type: "text",
    },
    {
      name: "Cumpleaños",
      field: "fecha_nacimiento",
      type: "date",
    },
  ];

  const datosLaboralesInputs = [
    {
      name: "Cargo",
      field: "cargo",
    },
    {
      name: "Jefe directo",
      field: "jefe_directo",
    },
    {
      name: "Fecha de ingreso",
      field: "fecha_ingreso",
    },
    {
      name: "Área",
      field: "area",
    },
    {
      name: "Departamento",
      field: "departamento",
    },
    {
      name: "Sucursal",
      field: "sucursal",
    },
  ];

  const showJefeModal = (e) => {
    e.preventDefault();
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const deleteJefeDirecto = () => {
    dispatch(
      colaboradorActions.colaboradorSet({
        ...colaborador,
        jefe_directo: null,
      })
    );
  };

  const renderComponenteLaboral = (field) => {
    const { jefe_directo } = colaborador;
    const props = {
      isDisabled: false,
      onChange: onChange(field),
      value: colaborador[field],
    };
    const components = {
      cargo: <CargoSelect {...props} />,
      jefe_directo: (
        <div className="flex items-center justify-between gap-2 border border-zinc-200 rounded-md p-2">
          <button className="flex flex-1" type="button" onClick={showJefeModal}>
            <div className="text-left flex-1">
              {!!jefe_directo
                ? `${jefe_directo.apellido}, ${jefe_directo.nombre}`
                : "Sin asignar"}
            </div>
          </button>
          {!!jefe_directo && (
            <button onClick={deleteJefeDirecto}>
              <X size={24} />
            </button>
          )}
        </div>
      ),
      fecha_ingreso: (
        <DatePicker
          className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 w-full"
          locale="es"
          // selected={Date.parse(colaborador[field])}
          selected={utcToZonedTime(
            colaborador[field],
            "America/Asuncion"
          ).getTime()}
          onChange={onChangeDate(field)}
          dateFormat="dd/MM/yyyy"
        />
      ),
      area: <AreaSelect defaultLabel="Sin Área" {...props} />,
      departamento: (
        <DepartamentoSelect defaultLabel="Sin Departamento" {...props} />
      ),
      sucursal: <SucursalSelect defaultLabel="Sin Sucursal" {...props} />,
    };
    const Component = components[field];
    return Component;
  };

  const getImagen = () => {
    if (colaborador.foto_empleado) {
      return colaborador.foto_empleado;
    }
    return userDefault;
  };

  const onChange =
    (field) =>
    ({ target }) => {
      setHasChanged(true);
      dispatch(
        colaboradorActions.colaboradorSet({
          ...colaborador,
          [field]: target.value,
        })
      );
    };

  const onChangeDate = (field) => (date) => {
    setHasChanged(true);
    dispatch(
      colaboradorActions.colaboradorSet({
        ...colaborador,
        [field]: date,
      })
    );
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let parsedFechaNacimiento = null;
    let parsedFechaingreso = null;
    const fecha_nacimiento = colaborador.fecha_nacimiento;
    const fecha_ingreso = colaborador.fecha_ingreso;
    const jefe_directo = colaborador.jefe_directo;
    //
    if (fecha_nacimiento) {
      parsedFechaNacimiento = dayjs(fecha_nacimiento).format("YYYY-MM-DD");
    }
    if (fecha_ingreso) {
      parsedFechaingreso = dayjs(fecha_ingreso).format("YYYY-MM-DD");
    }
    //
    dispatch(
      colaboradorActions.colaboradorSet({
        ...colaborador,
        fecha_nacimiento: parsedFechaNacimiento,
        fecha_ingreso: parsedFechaingreso,
        jefe_directo: !!jefe_directo ? jefe_directo.id : null,
      })
    );
    //
    if (colaborador.id) {
      dispatch(colaboradorActions.colaboradorUpdate());
      return;
    }
    dispatch(colaboradorActions.colaboradorCreate());
  };

  const uploadLogo = (file) => {
    dispatch(
      colaboradorActions.colaboradorSet({ ...colaborador, foto_empleado: file })
    );
    setShowImageInput(false);
    setHasChanged(true);
  };

  const modoVistaEdit = () => {
    setEsModoVista(false);
    dispatch(commonActions.setEditDetailButton(false));
  };

  const onCancelar = () => {
    setEsModoVista(true);
    dispatch(
      commonActions.setEditDetailButton({
        label: "Editar datos generales",
        action: modoVistaEdit,
      })
    );
    //
    setHasChanged(false);
    if (idColaborador) {
      dispatch(colaboradorActions.colaboradorGet({ id: idColaborador }));
    }
  };

  const areas = useSelector((state) => state.area.areas);
  const cargos = useSelector((state) => state.cargo.cargos);
  const departamentos = useSelector(
    (state) => state.departamento.departamentos
  );
  const sucursales = useSelector((state) => state.sucursal.sucursales);

  useEffect(() => {
    setEsModoVista(!!colaborador.id || !!idColaborador);
    setHasChanged(false);
    if (idColaborador) {
      dispatch(colaboradorActions.colaboradorGet({ id: idColaborador }));
    }
    //
  }, []);

  useEffect(() => {
    // datos para selectores de laboral
    dispatch(areaActions.areas());
    dispatch(cargoActions.cargos());
    dispatch(departamentoActions.departamentos());
    dispatch(sucursalActions.sucursales());
    dispatch(colaboradorActions.jefes());
  }, []);

  useEffect(() => {
    if (esModoVista)
      dispatch(
        commonActions.setEditDetailButton({
          label: "Editar datos generales",
          action: () => modoVistaEdit(),
        })
      );
  }, [esModoVista]);

  !!process.env.REACT_APP_DEBUG && console.log({ colaborador });

  // IMAGE CROPPER
  const [image, setImage] = useState("");
  const [imageStatus, setImageStatus] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setImageStatus("crop-img");
    setHasChanged(true);
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );
      !!process.env.REACT_APP_DEBUG &&
        console.info("imageObj1", typeof imageObj1, imageObj1);
      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      setImageStatus("img-cropped");
      //
      const type = dataURL.split(";")[0].split("/")[1];
      let imgObj = dataURLtoFile(
        dataURL,
        `${colaborador.nombre}-${colaborador.apellido}.${type}`
      );
      !!process.env.REACT_APP_DEBUG &&
        console.log("onCropDone dataURL", dataURL);
      //
      dispatch(
        colaboradorActions.colaboradorSet({
          ...colaborador,
          foto_empleado: imgObj,
        })
      );
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setImageStatus("choose-img");
    setImage("");
  };

  const onDeleteImage = () => {
    setImageStatus("choose-img");
    setImage("");
    dispatch(
      colaboradorActions.colaboradorSet({
        ...colaborador,
        foto_empleado: null,
      })
    );
  };
  //
  const jefes_filter = useSelector((state) => state.colaborador.jefes_filter);
  const { nombre: nombre_jefe = "" } = jefes_filter;
  const jefes_checked =
    useSelector((state) => state.colaborador.jefes_checked) || [];
  const jefesResults = useSelector((state) => state.colaborador.jefes) || [];
  const { results: jefes } = jefesResults || [];
  const ItemCommonComponent = ({ item }) => {
    return (
      <>
        <TableRow>
          <div
            className="p-4 flex gap-8 items-start justify-start cursor-pointer"
            onClick={() => {}}
          >
            <ProfileInfoCard
              name={`${item.nombre} ${item.apellido}`}
              image={item.foto_empleado ? item.foto_empleado : ""}
              nameWidth={48}
            ></ProfileInfoCard>
          </div>
        </TableRow>
      </>
    );
  };
  const changeJefeDirecto = () => {
    if (jefes_checked.length < 1) return;
    const selectedJefeId = jefes_checked[0];
    const jefe = jefes.find((jefe, idx) => jefe.id === selectedJefeId);
    //
    dispatch(
      colaboradorActions.colaboradorSet({
        ...colaborador,
        jefe_directo: jefe,
      })
    );
    //
    setHasChanged(true);
    dispatch(commonActions.setSelectModal({ open: false }));
  };
  const commonTableProps = {
    ItemComponent: ItemCommonComponent,
    subject: "colaborador",
    subjectGender: "M",
    hasAddBtn: true,
    customAddBtnLabel: "Asignar",
    noBottomButtom: true,
    selectOptions: [],
    checkType: "radio",
  };
  const modalTableConfig = {
    tableSecondTitle: "Jefes directos",
    addBtnClickHandler: () => changeJefeDirecto(),
    checkedState: "jefes",
    onCheckHandler: (event, item) => {
      dispatch(colaboradorActions.resetCheckJefes());
      dispatch(
        colaboradorActions.checkJefe({
          colaborador: item,
          checked: event.target.checked,
        })
      );
    },
  };
  const onChangeJefeText = (event) => {
    dispatch(colaboradorActions.setJefesFilter({ nombre: event.target.value }));
  };

  return (
    <>
      {!esModoVista && (
        <>
          <div className="flex justify-center items-center w-full mt-10">
            <div className="flex flex-col w-full md:w-5/6">
              <div className="grid grid-cols-1 md:grid-cols-12">
                {/* IDENTIFICADOR VISUAL */}
                <div className="col-span-1 md:col-span-4 flex flex-col  mb-4 md:mr-12">
                  <span className="font-bold text-lg">
                    Identificador visual
                  </span>
                  {imageStatus !== "img-cropped" && (
                    <div className="my-4 w-40 ">
                      <img
                        className="object-cover h-40 w-40 rounded-md"
                        src={getImagen()}
                        alt="Imagen del colaborador"
                      />
                    </div>
                  )}
                  {/*  */}
                  {imageStatus === "img-cropped" && (
                    <div className="mb-2">
                      <div className="my-4 w-40 border border-slate-200 bg-slate-200 rounded-md">
                        <img
                          className="object-cover h-40 w-40 rounded-md"
                          src={imgAfterCrop}
                          alt="Imagen del colaborador temp"
                        />
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => {
                            setImageStatus("crop-img");
                          }}
                          className="border border-black p-2 rounded-md"
                        >
                          Recortar
                        </button>

                        <button
                          onClick={onDeleteImage}
                          className="border border-red-500 bg-red-500 p-2 rounded-md text-white"
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  )}
                  {/*  */}
                  <div className="flex flex-col">
                    <label className="font-bold text-md" htmlFor="">
                      Imagen del colaborador:
                    </label>
                    {/*  */}
                    <div className="text-center p-2 border-2 border-sky-400 bg-white rounded-md">
                      <ImageInput
                        setImage={setImage}
                        onImageSelected={onImageSelected}
                      ></ImageInput>
                    </div>
                    <ImageUploadAlt
                      visible={showImageInput}
                      className={"mt-3 w-full"}
                      onSubmit={uploadLogo}
                    />
                  </div>
                </div>
                {/* DATOS PERSONALES */}
                <div className="col-span-1 md:col-span-8">
                  <div className="font-bold text-lg border-b mb-2 pb-2">
                    Datos personales
                  </div>
                  <form>
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      {datosPersonalesInputs.map((item, index) => {
                        //
                        if (item.field === "fecha_nacimiento")
                          return (
                            <div
                              className="col-span-1 mr-6 my-2 "
                              key={`form-group-${index}`}
                            >
                              <div className="flex flex-col">
                                <label
                                  className="font-bold text-base"
                                  htmlFor=""
                                >
                                  {item.name}:
                                </label>
                                <DatePicker
                                  className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 w-full"
                                  locale="es"
                                  // selected={Date.parse(colaborador[item.field])}
                                  selected={utcToZonedTime(
                                    colaborador[item.field],
                                    "America/Asuncion"
                                  ).getTime()}
                                  onChange={onChangeDate("fecha_nacimiento")}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                            </div>
                          );
                        return (
                          <div
                            className="col-span-1 mr-6 my-2 "
                            key={`form-group-${index}`}
                          >
                            <div className="flex flex-col">
                              <label className="font-bold text-base" htmlFor="">
                                {item.name}:
                                {!!item.required ? (
                                  <span className="text-red-500">*</span>
                                ) : (
                                  <span></span>
                                )}
                              </label>
                              {item.type !== "select" && (
                                <input
                                  type={item.type}
                                  name={item.field}
                                  value={colaborador[item.field] || ""}
                                  onChange={onChange(item.field)}
                                  placeholder="Sin especificar"
                                  className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2"
                                />
                              )}
                              {item.type == "select" && (
                                <Select
                                  value={item.options.filter(
                                    (v) => v.value == colaborador[item.field]
                                  )}
                                  onChange={(value) =>
                                    onChange(item.field)({ target: value })
                                  }
                                  options={item.options}
                                ></Select>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* DATOS LABORALES */}
                    {!colaborador.id && (
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        {datosLaboralesInputs.map((item, idx) => (
                          <>
                            <div
                              className="col-span-1 mr-6 my-2 "
                              key={`form-group-${idx}`}
                            >
                              <div className="flex flex-col">
                                <label
                                  className="font-bold text-base"
                                  htmlFor=""
                                >
                                  {item.name}
                                </label>
                                {renderComponenteLaboral(item.field)}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                    {/*  */}
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      {!esModoVista && (
                        <div className="col-span-2 mt-4 flex gap-4 py-4">
                          <button
                            onClick={onSubmit}
                            className={`${
                              hasChanged
                                ? "bg-sky-500  border-sky-500 "
                                : "bg-slate-400  border-slate-400 "
                            } border-2 rounded w-full px-4 py-2 text-white`}
                          >
                            Guardar datos
                          </button>
                          <button
                            onClick={onCancelar}
                            className={`bg-white border-black border rounded w-full px-4 py-2 text-black`}
                          >
                            Cancelar
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          {imageStatus !== "choose-img" && imageStatus !== "img-cropped" && (
            <div className="w-2/3 h-4/5 pb-4 absolute top-0 border border-blue-500 bg-[#7f7f7f]">
              {imageStatus === "crop-img" && (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                />
              )}
            </div>
          )}
          <SelectModal
            title={`Asignar ${modalTableConfig.tableSecondTitle}`}
            content={
              <TableCustom
                {...modalTableConfig}
                {...commonTableProps}
                data={jefes || []}
                searchValue={nombre_jefe}
                onChangeSearch={onChangeJefeText}
                itemsChecked={jefes_checked}
                multiple={false}
                fixedHeigth={true}
              />
            }
          ></SelectModal>
        </>
      )}
      {/* ===== VISTA DETALLE ===== */}
      {!!esModoVista && (
        <div className="flex justify-center items-center w-full mt-10">
          <div className="flex flex-col w-full md:w-5/6">
            <div className="grid grid-cols-1 md:grid-cols-12">
              {/* IDENTIFICADOR VISUAL */}
              <div className="col-span-1 md:col-span-4 flex flex-col  mb-4 md:mr-12">
                <span className="font-bold text-lg">Identificador visual</span>
                <div className="my-4 w-40 border border-slate-200 bg-slate-200 rounded-md">
                  {imageStatus !== "img-cropped" && (
                    <img
                      className="object-cover h-40 w-40 rounded-md"
                      src={getImagen()}
                      alt="Imagen del colaborador"
                    />
                  )}
                  {imageStatus === "img-cropped" && (
                    <img
                      className="object-cover h-40 w-40 rounded-md"
                      src={imgAfterCrop}
                      alt="Imagen del colaborador temp"
                    />
                  )}
                </div>
              </div>
              {/* DATOS PERSONALES */}
              <div className="col-span-1 md:col-span-8 ">
                <div className="font-bold text-lg border-b mb-2 pb-2">
                  Datos personales
                </div>
                <div className="datos-container">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    {datosPersonalesInputs.map((item, index) => {
                      let showValue = colaborador[item.field];
                      if (item.field === "fecha_nacimiento")
                        showValue = showValue
                          ? dayjs(showValue).format("DD/MM/YYYY") //.slice(0, 10)
                          : null;
                      if (item.field === "estado_civil")
                        showValue = estadoCivilDict[showValue];
                      if (item.field === "sexo")
                        showValue =
                          showValue === "F" ? "Femenino" : "Masculino";
                      //
                      return (
                        <div
                          className="col-span-1 mr-6 my-2 "
                          key={`form-group-${index}`}
                        >
                          <div className="flex flex-col">
                            <label className="font-bold text-base" htmlFor="">
                              {item.name}:
                            </label>
                            <div className="py-4">
                              {showValue || "Sin especificar"}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ColaboradorGeneralPage;
