import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import { competenciaPotencialActions } from "../handlers/redux";
import CompetenciasLayout from "../../common/components/CompetenciasLayout";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { CompetenciaPieChart } from "../../common/components/CompetenciaPieChart.";
import PlanAvanzadoAlert from "../../common/components/PlanAvanzadoAlert";
import CompetenciaLoading from "../../common/components/CompetenciaLoading";
import CompetenciaModal from "../../common/components/CompetenciaModal";
import { CompetenciaCollapsable } from "../../common/components/CompetenciaCollapsable";

const CompetenciaPotencialPage = () => {
  const dispatch = useDispatch();

  const empresa = useSelector((state) => state.empresa.empresa);
  const competencias_potencial = useSelector(
    (state) => state.competenciaPotencial.competencias_potencial
  );
  const competencias_potencial_loading = useSelector(
    (state) => state.competenciaPotencial.competencias_potencial_loading
  );
  const competencias_potencial_edit = useSelector(
    (state) => state.competenciaPotencial.competencias_potencial_edit
  );
  const competencias_potencial_aux = useSelector(
    (state) => state.competenciaPotencial.competencias_potencial_aux
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [competencia, setCompetencia] = useState({});
  const [aplicarPesos, setAplicarPesos] = useState(false);
  const [idx, setIdx] = useState(null);

  useEffect(() => {
    dispatch(empresaActions.empresa());
    dispatch(competenciaPotencialActions.getCompetenciasPotencial());
  }, []);

  const tienePlanAvanzado = () => {
    return empresa.tipo_plan === "Avanzado";
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(competencias_potencial_aux);
    dispatch(
      competenciaPotencialActions.setAplicarPesosPotenciales(aplicarPesos)
    );
    dispatch(competenciaPotencialActions.updateCompetenciasPotenciales());
  };

  const onChange = (competencia) => {
    dispatch(
      competenciaPotencialActions.setCompetenciasPotencialAux(competencia)
    );
  };

  const onClickEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      competenciaPotencialActions.setCompetenciasPotencialAux(
        competencias_potencial
      )
    );
    dispatch(competenciaPotencialActions.setCompetenciasPotencialEdit(true));
  };

  const onClickEditCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(competenciaPotencialActions.setCompetenciasPotencialAux([]));
    dispatch(competenciaPotencialActions.setCompetenciasPotencialEdit(false));
  };

  const onAplicarPesoCheck = (e) => {
    setAplicarPesos(e.target.checked);
  };

  const onClickAdd = () => {
    setCompetencia({});
    setIdx(null);
    setModalOpen(true);
  };

  const onSubmitCompetencia = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!competencia.nombre) return;

    let { conductas = [] } = competencia;
    conductas = conductas.filter((c) => !!c.descripcion);

    onChangeCompentencia({ ...competencia, conductas }, idx);
    setModalOpen(false);
  };

  const onEdit = (idx) => (competencia) => {
    setCompetencia({ ...competencia });
    setModalOpen(true);
    setIdx(idx);
  };

  const onDelete = (idx) => (competencia) => {
    let deseaEliminar = window.confirm(
      "¿Desea eliminar la competencia seleccionada?"
    );
    if (!!deseaEliminar) {
      setIdx(idx);
      let competencias_new = competencias_potencial_aux.filter(
        (_, cIdx) => idx != cIdx
      );
      onChange(competencias_new);
    }
  };

  const onChangeCompentencia = (competencia, idx) => {
    let competencias_new = [...competencias_potencial_aux];
    if (idx == null) {
      competencias_new.push(competencia);
    } else {
      competencias_new[idx] = competencia;
    }
    onChange(competencias_new);
  };

  const renderListaCompetencias = () => {
    const items = competencias_potencial_edit
      ? competencias_potencial_aux
      : competencias_potencial;
    return (
      <div className="flex flex-col gap-3">
        {items.length > 0 &&
          items.map((competencia, idx) => (
            <CompetenciaCollapsable
              key={competencia.id}
              competencia={competencia}
              editable={!!competencias_potencial_edit}
              showInput={!!tienePlanAvanzado() && !!aplicarPesos}
              onEdit={competencias_potencial_edit ? onEdit(idx) : null}
              onDelete={competencias_potencial_edit ? onDelete(idx) : null}
              onChange={onChangeCompentencia}
              idx={idx}
            />
          ))}
        {items.length === 0 && (
          <div className="flex items-center justify-center h-20 border border-slate-200 rounded-md">
            No existen Competencias para mostrar.
          </div>
        )}
      </div>
    );
  };

  return (
    <CompetenciasLayout>
      <div className="flex flex-col items-center mt-4">
        <form
          onSubmit={onSubmit}
          className="flex flex-col w-full md:w-5/6 gap-3"
        >
          <div className="flex justify-between items-center text-lg font-bold border-b py-2">
            <div>Competencias de potenciales</div>
            {!competencias_potencial_loading && (
              <>
                {!competencias_potencial_edit && (
                  <PrimaryButton type="button" onClick={onClickEdit}>
                    {competencias_potencial.length > 0 ? "Editar" : "Agregar"}
                  </PrimaryButton>
                )}
                {!!competencias_potencial_edit && (
                  <div className="flex gap-2">
                    <PrimaryButton type="submit">Guardar</PrimaryButton>

                    <PrimaryButton
                      className="bg-red-500"
                      onClick={onClickEditCancel}
                    >
                      Cancelar
                    </PrimaryButton>
                  </div>
                )}
              </>
            )}
          </div>

          <div className={`flex flex-col gap-3 pr-3`}>
            <div className="flex flex-row gap-2 items-center">
              {!!competencias_potencial_edit && !!tienePlanAvanzado() && (
                <>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="m-0 p-0 h-4 w-4 accent-sky-600"
                      checked={aplicarPesos}
                      onChange={onAplicarPesoCheck}
                    />
                  </div>
                  <div className="font-bold text-sm">
                    Aplicar pesos a las competencias
                  </div>
                </>
              )}
            </div>
            {/* GRÁFICO */}
            <div className="flex flex-col items-center gap-2">
              {!!competencias_potencial_edit &&
                !!tienePlanAvanzado() &&
                !!aplicarPesos && (
                  <div className="">
                    <CompetenciaPieChart
                      competencias={
                        competencias_potencial_edit
                          ? competencias_potencial_aux
                          : competencias_potencial
                      }
                    />
                  </div>
                )}
              {!tienePlanAvanzado() && <PlanAvanzadoAlert />}
            </div>
            {/* INDICADORES */}
            <div className="flex flex-col gap-2">
              {/* title */}
              <div className="flex flex-row justify-between font-bold">
                <div className="after:content-['*'] after:ml-0.5 after:text-red-500">
                  Competencias a evaluar
                </div>
                {!!tienePlanAvanzado() && !!aplicarPesos && (
                  <div className="w-24 after:content-['*'] after:ml-0.5 after:text-red-500">
                    Peso
                  </div>
                )}
              </div>
              {/* LISTA */}
              {!competencias_potencial_loading && renderListaCompetencias()}
              {/* loading */}
              {!!competencias_potencial_loading &&
                [0, 1, 2].map(() => <CompetenciaLoading />)}
              {!competencias_potencial_loading &&
                !!competencias_potencial_edit && (
                  <div
                    className="flex flex-row gap-2 items-center pl-3"
                    onClick={onClickAdd}
                  >
                    <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                      +
                    </div>
                    <div className="text-sky-500 text-sm">
                      Agregar otra competencia a evaluar
                    </div>
                  </div>
                )}
              <CompetenciaModal
                open={modalOpen}
                setOpen={setModalOpen}
                competencia={competencia}
                onChange={setCompetencia}
                onSubmit={onSubmitCompetencia}
                idx={idx}
                especifica={false}
              />
            </div>
          </div>
          {!competencias_potencial_loading && (
            <div className="flex flex-col gap-2 mb-2">
              {!!competencias_potencial_edit && (
                <PrimaryButton type="submit">
                  Guardar configuración de Competencias
                </PrimaryButton>
              )}
              {!!competencias_potencial_edit && (
                <PrimaryButton
                  className="bg-red-500"
                  onClick={onClickEditCancel}
                >
                  Cancelar
                </PrimaryButton>
              )}
              {!competencias_potencial_edit && (
                <PrimaryButton type="button" onClick={onClickEdit}>
                  {competencias_potencial.length > 0 ? "Editar" : "Agregar"}
                </PrimaryButton>
              )}
            </div>
          )}
        </form>
      </div>
    </CompetenciasLayout>
  );
};

export default CompetenciaPotencialPage;
