import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const competenciaPotencialApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    list: async () => {
      const response = await axios.get(`${URL_BASE}/competencia/potencial/`, {
        headers,
      });
      return response.data;
    },
    updateCompetenciasPotencial: async (params) => {
      const response = await axios.put(
        `${URL_BASE}/competencia/potencial/`,
        params,
        {
          headers,
        }
      );
      return response.data;
    },
  };
};
