import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { commonActions } from "src/modules/common/handler/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { getToken, ObjectToString } from "src/utils/helpers";
import { colaboradorApi } from "./api";
import { colaboradorActions } from "./redux";

function* colaboradorDeleteSaga({ payload }) {
  const token = getToken();
  const colaborador = payload;
  const params = { ...colaborador };
  //
  try {
    const data = yield call(colaboradorApi(token).delete, params);
    yield put(push("/nomina"));
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.colaboradorDeleteSuccess());
    SuccessToast({ message: "Colaborador eliminado con éxito!" });
  } catch (error) {
    yield put(colaboradorActions.colaboradorDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorUpdateSaga({ payload }) {
  const token = getToken();
  const colaborador = yield select((state) => state.colaborador.colaborador);
  const params = { ...colaborador };
  //
  const tieneCIoEmail = params.ci || params.email;
  if (!(params.nombre && params.apellido && tieneCIoEmail)) {
    ErrorToast({ message: "Faltan campos obligatorios" });
    return;
  }
  if (typeof params.foto_empleado === "string") {
    params.foto_empleado = null;
  }
  //
  try {
    const data = yield call(colaboradorApi(token).update, params);
    SuccessToast({ message: "Colaborador editado con éxito!" });
    yield put(colaboradorActions.colaboradorUpdateFinish(true));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const data = error.response?.data || {};
    let errorMessage = data.error || data.non_field_errors || "Ocurrió un error inesperado";
    //
    if (errorMessage.includes("unique_ci_empresa")) {
      errorMessage = "Ya existe un colaborador con la cedula ingresada.";
    } else if (Array.isArray(errorMessage)) {
      errorMessage = errorMessage[0];
    }
    ErrorToast({ message: errorMessage });
    yield put(colaboradorActions.colaboradorUpdateFinish(false));
  }
}

function* colaboradorCreateSaga({ payload }) {
  const token = getToken();
  const colaborador = yield select((state) => state.colaborador.colaborador);
  const params = { ...colaborador };
  //
  const tieneCIoEmail = params.ci || params.email;
  if (!(params.nombre && tieneCIoEmail)) {
    ErrorToast({ message: "Faltan campos obligatorios" });
    return;
  }
  //
  try {
    const data = yield call(colaboradorApi(token).create, params);
    yield put(push("/nomina"));
    yield put(colaboradorActions.colaboradores());
    SuccessToast({ message: "Colaborador creado con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const data = error.response?.data || {};
    let errorMessage = data.error || data.non_field_errors || "Ocurrió un error inesperado";
    //
    if (errorMessage.includes("unique_ci_empresa")) {
      errorMessage = "Ya existe un colaborador con la cedula ingresada.";
    } else if (Array.isArray(errorMessage)) {
      errorMessage = errorMessage[0];
    }
    ErrorToast({ message: errorMessage });
  } finally {
    yield put(colaboradorActions.colaboradorCreateFinish());
  }
}

function* colaboradorGetSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).get, payload);
    yield put(colaboradorActions.colaboradorSet(data));

    yield put(colaboradorActions.colaboradorAccesoGet(payload));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradoresSaga({ payload }) {
  const token = getToken();
  try {
    const colaboradoresFilter = yield select(
      (state) => state.colaborador.colaboradores_filter
    );
    const data = yield call(colaboradorApi(token).list, {
      colaboradoresFilter,
    });
    !!process.env.REACT_APP_DEBUG && console.info("colaboradoresSaga", 1);
    yield put(colaboradorActions.setColaboradores({ colaboradores: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* jefesSaga({ payload }) {
  const token = getToken();
  try {
    const jefesFilter = yield select((state) => state.colaborador.jefes_filter);
    !!process.env.REACT_APP_DEBUG &&
      console.info("colaboradoresFilter sagas", jefesFilter);
    const data = yield call(colaboradorApi(token).list, {
      colaboradoresFilter: jefesFilter,
    });
    !!process.env.REACT_APP_DEBUG && console.info("jefes saga", data);
    yield put(colaboradorActions.setJefes({ jefes: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradoresLibresSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).colaboradoresLibres, payload);
    yield put(
      colaboradorActions.setColaboradoresLibres({ colaboradores_libres: data })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

// Acceso
function* colaboradorAccesoGetSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).getAcceso, payload);
    yield put(colaboradorActions.colaboradorAccesoSet(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradorAccesoInvitacionSaga({ payload = {} }) {
  const token = getToken();
  const colaborador = yield select((state) => state.colaborador.colaborador);
  const colaborador_acceso = yield select(
    (state) => state.colaborador.colaborador_acceso
  );
  const forzarBienvenida = payload.forzarBienvenida || false;
  const retornarLink = payload.retornarLink || false;
  const { groups = [] } = colaborador_acceso;

  const params = {
    id: colaborador.id,
    forzarBienvenida: forzarBienvenida,
    retornarLink: retornarLink,
  };

  try {
    const data = yield call(colaboradorApi(token).invitacionAcceso, params);
    if (data.data?.url) {
      yield put(colaboradorActions.setLinkInvitacion(data.data?.url));
    } else {
      SuccessToast({ message: "Invitación enviada con éxito!" });
    }
    //
    yield put(colaboradorActions.colaboradorAccesoInvitacionSuccess());
    yield put(push("/nomina"));
    yield put(colaboradorActions.colaboradores());
  } catch (error) {
    yield put(colaboradorActions.colaboradorAccesoInvitacionSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorAccesoUpdateSaga({ payload }) {
  const token = getToken();
  const colaborador_acceso = yield select(
    (state) => state.colaborador.colaborador_acceso
  );
  const params = {
    ...colaborador_acceso,
  };
  !!process.env.REACT_APP_DEBUG &&
    console.info("colaboradorAccesoUpdateSaga", params);
  try {
    const data = yield call(colaboradorApi(token).updateAcceso, params);
    yield put(colaboradorActions.colaboradorAccesoUpdateSuccess());
  } catch (error) {
    yield put(colaboradorActions.colaboradorAccesoUpdateSuccess());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* rolesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).roles);
    !!process.env.REACT_APP_DEBUG && console.info("rolesSaga", data);
    yield put(colaboradorActions.setRoles(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

// Laboral
function* colaboradorLaboralGetSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).getLaboral, payload);
    yield put(colaboradorActions.colaboradorLaboralSet(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradorLaboralUpdateSaga({ payload }) {
  const token = getToken();
  const colaborador_laboral = yield select(
    (state) => state.colaborador.colaborador_laboral
  );
  const { colaboradores = [] } = colaborador_laboral;
  const params = {
    ...colaborador_laboral,
    colaboradores: colaboradores.map((c) => c.id),
  };
  try {
    const data = yield call(colaboradorApi(token).updateLaboral, params);
    yield put(colaboradorActions.colaboradorLaboralUpdateSuccess());
    yield put(push("/nomina"));
    yield put(colaboradorActions.colaboradores());
  } catch (error) {
    yield put(colaboradorActions.colaboradorLaboralUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

// Acciones masivas
function* asignarSucursalSaga({ payload }) {
  const token = getToken();
  const colaboradores = yield select(
    (state) => state.colaborador.colaboradores_checked
  );
  const sucursal = yield select((state) => state.sucursal.sucursales_checked);
  let withErrors = false;
  // Verificacion
  if (colaboradores.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  const quitar = payload?.quitar ?? false;
  if (sucursal.length < 1 && !quitar) {
    ErrorToast({ message: "No se ha seleccionado Sucursal." });
    withErrors = true;
  }
  if (withErrors) {
    yield put(colaboradorActions.asignacionComplete());
    return;
  }
  //
  const params = {
    sucursal: sucursal[0],
    colaboradores: colaboradores,
    quitar: quitar,
  };
  !!process.env.REACT_APP_DEBUG &&
    console.info("asignarSucursalSaga params", params);
  try {
    const data = yield call(colaboradorApi(token).asignarSucursal, params);
    //
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(sucursalActions.resetCheckSucursales());
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    yield put(colaboradorActions.asignacionComplete());
    let message = "Sucursal asignada con éxito!";
    if (!!quitar) message = "Sucursales desvinculadas con éxito!";
    SuccessToast({ message: message });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorActions.asignacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* asignarAreaSaga({ payload }) {
  const token = getToken();
  const colaboradores = yield select(
    (state) => state.colaborador.colaboradores_checked
  );
  const area = yield select((state) => state.area.areas_checked);
  let withErrors = false;
  // Verificacion
  if (colaboradores.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  const quitar = payload?.quitar ?? false;
  if (area.length < 1 && !quitar) {
    ErrorToast({ message: "No se ha seleccionado Área." });
    withErrors = true;
  }
  if (withErrors) {
    yield put(colaboradorActions.asignacionComplete());
    return;
  }
  //
  const params = {
    area: area[0],
    colaboradores: colaboradores,
    quitar: quitar,
  };
  try {
    const data = yield call(colaboradorApi(token).asignarArea, params);
    // ver para cerrar modal y recargar colaboradores?
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(areaActions.resetCheckAreas());
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    let message = "Área asignada con éxito!";
    if (!!quitar) message = "Áreas desvinculadas con éxito!";
    SuccessToast({ message: message });
    yield put(colaboradorActions.asignacionComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorActions.asignacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* asignarDepartamentoSaga({ payload }) {
  const token = getToken();
  const colaboradores = yield select(
    (state) => state.colaborador.colaboradores_checked
  );
  const departamento = yield select(
    (state) => state.departamento.departamentos_checked
  );
  let withErrors = false;
  // Verificacion
  if (colaboradores.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  const quitar = payload?.quitar ?? false;
  if (departamento.length < 1 && !quitar) {
    ErrorToast({ message: "No se ha seleccionado Departamento." });
    withErrors = true;
  }
  if (withErrors) {
    yield put(colaboradorActions.asignacionComplete());
    return;
  }
  //
  const params = {
    departamento: departamento[0],
    colaboradores: colaboradores,
    quitar: quitar,
  };
  try {
    const data = yield call(colaboradorApi(token).asignarDepartamento, params);
    //
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(departamentoActions.resetCheckDepartamentos());
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    let message = "Departamento asignado con éxito!";
    if (!!quitar) message = "Departamentos desvinculados con éxito!";
    SuccessToast({ message: message });
    yield put(colaboradorActions.asignacionComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorActions.asignacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* asignarCargoSaga({ payload }) {
  const token = getToken();
  const colaboradores = yield select(
    (state) => state.colaborador.colaboradores_checked
  );
  const cargo = yield select((state) => state.cargo.cargos_checked);
  let withErrors = false;
  // Verificacion
  if (colaboradores.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  const quitar = payload?.quitar ?? false;
  if (cargo.length < 1 && !quitar) {
    ErrorToast({ message: "No se ha seleccionado Cargo." });
    withErrors = true;
  }
  if (withErrors) {
    yield put(colaboradorActions.asignacionComplete());
    return;
  }
  //
  const params = {
    cargo: cargo[0],
    colaboradores: colaboradores,
    quitar: quitar,
  };
  try {
    const data = yield call(colaboradorApi(token).asignarCargo, params);
    //
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(cargoActions.resetCheckCargos());
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    let message = "Cargo asignado con éxito!";
    if (!!quitar) message = "Cargos desvinculados con éxito!";
    SuccessToast({ message: message });
    yield put(colaboradorActions.asignacionComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorActions.asignacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* asignarJefeSaga({ payload }) {
  const token = getToken();
  const colaboradores = yield select(
    (state) => state.colaborador.colaboradores_checked
  );
  const jefe = yield select((state) => state.colaborador.jefes_checked);
  let withErrors = false;
  // Verificacion
  if (colaboradores.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  const quitar = payload?.quitar ?? false;
  if (jefe.length < 1 && !quitar) {
    ErrorToast({ message: "No se ha seleccionado Jefe Directo." });
    withErrors = true;
  }
  if (withErrors) {
    yield put(colaboradorActions.asignacionComplete());
    return;
  }
  //
  const params = {
    jefe: jefe[0],
    colaboradores: colaboradores,
    quitar: quitar,
  };
  try {
    const data = yield call(colaboradorApi(token).asignarJefe, params);
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(colaboradorActions.resetCheckJefes());
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    let message = "Jefe asignado con éxito!";
    if (!!quitar) message = "Jefes desvinculados con éxito!";
    SuccessToast({ message: message });
    yield put(colaboradorActions.asignacionComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorActions.asignacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* asignarRolSaga({ payload }) {
  const token = getToken();
  const colaboradores = yield select(
    (state) => state.colaborador.colaboradores_checked
  );
  const rol = yield select((state) => state.colaborador.roles_checked);
  let withErrors = false;
  // Verificacion
  if (colaboradores.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  const quitar = payload?.quitar ?? false;
  if (rol.length < 1 && !quitar) {
    ErrorToast({ message: "No se ha seleccionado Rol de Acceso." });
    withErrors = true;
  }
  if (withErrors) {
    yield put(colaboradorActions.asignacionComplete());
    return;
  }
  //
  const params = { rol: rol[0], colaboradores: colaboradores, quitar: quitar };
  try {
    const data = yield call(colaboradorApi(token).asignarRol, params);
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(colaboradorActions.resetCheckRoles());
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    let message = "Rol asignado con éxito!";
    if (!!quitar) message = "Roles desvinculados con éxito!";
    SuccessToast({ message: message });
    yield put(colaboradorActions.asignacionComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorActions.asignacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradoresDeleteSaga({ payload }) {
  const token = getToken();
  const colaboradoresDelete = payload;
  let withErrors = false;
  // Verificacion
  if (colaboradoresDelete.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  if (withErrors) return;
  //
  try {
    const data = yield call(colaboradorApi(token).deleteColaboradores, {
      colaboradores: colaboradoresDelete,
    });
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    SuccessToast({ message: "Colaboradores eliminados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* enviarInvitacionesSaga({ payload }) {
  const token = getToken();
  const colaboradores = yield select(
    (state) => state.colaborador.colaboradores_checked
  );
  const forzarBienvenida = payload.forzarBienvenida || false;
  let withErrors = false;
  // Verificacion
  if (colaboradores.length < 1) {
    ErrorToast({ message: "No se han seleccionado Colaboradores." });
    withErrors = true;
  }
  if (withErrors) return;
  const params = {
    colaboradores: colaboradores,
    forzarBienvenida: forzarBienvenida,
  };
  try {
    const data = yield call(colaboradorApi(token).enviarInvitaciones, params);
    //
    yield put(colaboradorActions.colaboradores());
    yield put(colaboradorActions.resetCheckColaboradores());
    SuccessToast({ message: "Invitaciones enviadas con éxito!" });
    yield put(colaboradorActions.asignacionComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorActions.asignacionComplete());
  }
}

// Importación
function* colaboradoresImportarVerificarSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.colaborador.fileToUpload);
  try {
    const data = yield call(colaboradorApi(token).importarVerificar, {
      fileToUpload,
    });
    yield put(colaboradorActions.setDatosVerificados(data));
    yield put(push("/nomina/importador"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG &&
      console.log("colaboradoresImportarVerificarSaga error");
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Hubieron errores en la importación!" });
    yield put(
      colaboradorActions.setErroresImportacion(error.response.data.errores)
    );
  }
}

function* colaboradoresImportarSaga({ payload }) {
  const token = getToken();
  const datos = yield select((state) => state.colaborador.datosImportar);
  try {
    const data = yield call(colaboradorApi(token).importar, datos);
    yield put(colaboradorActions.importarDatosComplete());
    SuccessToast({ message: "Colaboradores importados con éxito!" });
    yield put(push("/nomina"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradoresImportarGeneralVerificarSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.colaborador.fileToUpload);
  try {
    const data = yield call(colaboradorApi(token).importarGeneralVerificar, {
      fileToUpload,
    });
    yield put(colaboradorActions.setDatosVerificados(data));
    yield put(push("/nomina/importador-general"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG &&
      console.log("colaboradoresImportarVerificarSaga error");
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Hubieron errores en la importación!" });
    yield put(
      colaboradorActions.setErroresImportacion(error.response.data.errores)
    );
  }
}

function* colaboradoresImportarGeneralSaga({ payload }) {
  const token = getToken();
  const datos = yield select((state) => state.colaborador.datosImportarGeneral);
  //
  try {
    const data = yield call(colaboradorApi(token).importarGeneral, datos);
    yield put(colaboradorActions.importarDatosComplete());
    //
    yield put(sucursalActions.importarDatosComplete());
    yield put(areaActions.importarDatosComplete());
    yield put(departamentoActions.importarDatosComplete());
    yield put(cargoActions.importarDatosComplete());
    //
    SuccessToast({ message: "Colaboradores importados con éxito!" });
    yield put(push("/nomina"));
  } catch (error) {
    yield put(colaboradorActions.importarDatosError());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradoresImportarGeneralFullVerificarSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.colaborador.fileToUpload);
  try {
    const data = yield call(
      colaboradorApi(token).importarGeneralFullVerificar,
      {
        fileToUpload,
      }
    );
    yield put(colaboradorActions.setDatosVerificados(data));
    yield put(push("/nomina/importador-general"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG &&
      console.log("colaboradoresImportarVerificarSaga error");
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Hubieron errores en la importación!" });
    yield put(
      colaboradorActions.setErroresImportacion(error.response.data.errores)
    );
  }
}

function* colaboradoresImportarGeneralFullSaga({ payload }) {
  const token = getToken();
  const datos = yield select(
    (state) => state.colaborador.datosImportarGeneralFull
  );
  //
  try {
    const data = yield call(colaboradorApi(token).importarGeneralFull, datos);
    yield put(colaboradorActions.importarDatosComplete());
    //
    yield put(sucursalActions.importarDatosComplete());
    yield put(areaActions.importarDatosComplete());
    yield put(departamentoActions.importarDatosComplete());
    yield put(cargoActions.importarDatosComplete());
    //
    SuccessToast({ message: "Colaboradores importados con éxito!" });
    yield put(push("/nomina"));
  } catch (error) {
    yield put(colaboradorActions.importarDatosError());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* tiposContratacionesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).tiposContrataciones);
    yield put(colaboradorActions.setTiposContrataciones(data));
  } catch (error) {
    yield put(colaboradorActions.setTiposContrataciones([]));
  }
}

export function* colaboradorSagas() {
  // create
  yield takeEvery(
    colaboradorActions.colaboradorCreate.type,
    colaboradorCreateSaga
  );
  // get
  yield takeEvery(colaboradorActions.colaboradorGet.type, colaboradorGetSaga);
  // update
  yield takeEvery(
    colaboradorActions.colaboradorUpdate.type,
    colaboradorUpdateSaga
  );
  // delete
  yield takeEvery(
    colaboradorActions.colaboradorDelete.type,
    colaboradorDeleteSaga
  );
  // list
  yield takeLatest(colaboradorActions.colaboradores.type, colaboradoresSaga);
  yield takeLatest(
    colaboradorActions.setColaboradoresFilter.type,
    colaboradoresSaga
  );
  yield takeLatest(colaboradorActions.jefes.type, jefesSaga);
  yield takeLatest(colaboradorActions.setJefesFilter.type, jefesSaga);
  // list libres
  yield takeEvery(
    colaboradorActions.colaboradoresLibres.type,
    colaboradoresLibresSaga
  );
  // ACCESO
  // acceso get
  yield takeEvery(
    colaboradorActions.colaboradorAccesoGet.type,
    colaboradorAccesoGetSaga
  );
  yield takeEvery(colaboradorActions.roles.type, rolesSaga);
  // update
  yield takeEvery(
    colaboradorActions.colaboradorAccesoInvitacion.type,
    colaboradorAccesoInvitacionSaga
  );
  yield takeEvery(
    colaboradorActions.colaboradorAccesoUpdate.type,
    colaboradorAccesoUpdateSaga
  );
  // LABORAL
  // laboral get
  yield takeEvery(
    colaboradorActions.colaboradorLaboralGet.type,
    colaboradorLaboralGetSaga
  );
  // update
  yield takeEvery(
    colaboradorActions.colaboradorLaboralUpdate.type,
    colaboradorLaboralUpdateSaga
  );
  // Importacion
  yield takeEvery(
    colaboradorActions.setFileToUpload.type,
    colaboradoresImportarVerificarSaga
  );
  yield takeEvery(
    colaboradorActions.setFileToUploadGeneral.type,
    colaboradoresImportarGeneralVerificarSaga
  );
  yield takeEvery(
    colaboradorActions.setFileToUploadGeneralFull.type,
    colaboradoresImportarGeneralFullVerificarSaga
  );
  yield takeEvery(
    colaboradorActions.setDatosImportarGeneral.type,
    colaboradoresImportarGeneralSaga
  );
  yield takeEvery(
    colaboradorActions.setDatosImportar.type,
    colaboradoresImportarSaga
  );
  yield takeEvery(
    colaboradorActions.setDatosImportarGeneralFull.type,
    colaboradoresImportarGeneralFullSaga
  );
  // Acciones Masivas
  yield takeEvery(colaboradorActions.asignarSucursal.type, asignarSucursalSaga);
  yield takeEvery(colaboradorActions.asignarArea.type, asignarAreaSaga);
  yield takeEvery(
    colaboradorActions.asignarDepartamento.type,
    asignarDepartamentoSaga
  );
  yield takeEvery(colaboradorActions.asignarCargo.type, asignarCargoSaga);
  yield takeEvery(colaboradorActions.asignarJefe.type, asignarJefeSaga);
  yield takeEvery(colaboradorActions.asignarRol.type, asignarRolSaga);
  yield takeEvery(
    colaboradorActions.setColaboradoresDelete,
    colaboradoresDeleteSaga
  );
  yield takeEvery(
    colaboradorActions.enviarInvitaciones.type,
    enviarInvitacionesSaga
  );

  yield takeEvery(
    colaboradorActions.getTiposContrataciones.type,
    tiposContratacionesSaga
  );
}
