import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "src/modules/common/components/CustomTable";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import { evaluacionActions } from "../handlers/redux";
import CustomAlert from "src/modules/common/components/CustomAlert";

function EvaluacionCambiosCompetencias() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { idEvaluacion } = params;

  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const cambios_competencias = useSelector(
    (state) => state.evaluacion.cambios_competencias
  );
  const cambios_competencias_checked = useSelector(
    (state) => state.evaluacion.cambios_competencias_checked
  );
  const cambios_competencias_loading = useSelector(
    (state) => state.evaluacion.cambios_competencias_loading
  );
  const actualizar_nomina_loading = useSelector(
    (state) => state.evaluacion.actualizar_nomina_loading
  );
  const {
    nuevos_generales = [],
    modificados_generales = [],
    eliminados_generales = [],
    //
    modificados_grupos_cargos = [],
    nuevos_cargos = [],
    modificados_cargos = [],
    eliminados_cargos = [],
    //
    modificados_grupos_areas = [],
    nuevos_areas = [],
    modificados_areas = [],
    eliminados_areas = [],
    //
    nuevos_potenciales = [],
    modificados_potenciales = [],
    eliminados_potenciales = [],
  } = cambios_competencias;

  const [Cambios, setCambios] = useState([]);
  const [SearchValue, setSearchValue] = useState("");

  const getData = () => {
    let data = [];
    const nuevosGenerales = nuevos_generales.map((item, idx) => ({
      ...item,
      estado: "Nuevo [General]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const modificadosGenerales = modificados_generales.map((item, idx) => ({
      ...item,
      estado: "Modificado [General]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const eliminadosGenerales = eliminados_generales.map((item, idx) => ({
      ...item,
      estado: "Eliminado [General]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    //
    const modificadosGruposCargos = modificados_grupos_cargos.map(
      (item, idx) => ({
        ...item,
        estado: "Modificado [Grupo Cargo]", // Si se modifica este, influye en el backend al intentar actualizar
      })
    );
    const nuevosCargos = nuevos_cargos.map((item, idx) => ({
      ...item,
      estado: "Nuevo [Cargo]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const modificadosCargos = modificados_cargos.map((item, idx) => ({
      ...item,
      estado: "Modificado [Cargo]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const eliminadosCargos = eliminados_cargos.map((item, idx) => ({
      ...item,
      estado: "Eliminado [Cargo]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    //
    const modificadosGruposAreas = modificados_grupos_areas.map(
      (item, idx) => ({
        ...item,
        estado: "Modificado [Grupo Área]", // Si se modifica este, influye en el backend al intentar actualizar
      })
    );
    const nuevosAreas = nuevos_areas.map((item, idx) => ({
      ...item,
      estado: "Nuevo [Área]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const modificadosAreas = modificados_areas.map((item, idx) => ({
      ...item,
      estado: "Modificado [Área]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const eliminadosAreas = eliminados_areas.map((item, idx) => ({
      ...item,
      estado: "Eliminado [Área]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    //
    const nuevosPotenciales = nuevos_potenciales.map((item, idx) => ({
      ...item,
      estado: "Nuevo [Potencial]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const modificadosPotenciales = modificados_potenciales.map((item, idx) => ({
      ...item,
      estado: "Modificado [Potencial]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    const eliminadosPotenciales = eliminados_potenciales.map((item, idx) => ({
      ...item,
      estado: "Eliminado [Potencial]", // Si se modifica este, influye en el backend al intentar actualizar
    }));
    //
    data = [
      ...nuevosGenerales,
      ...modificadosGenerales,
      ...eliminadosGenerales,
      //
      ...modificadosGruposCargos,
      ...nuevosCargos,
      ...modificadosCargos,
      ...eliminadosCargos,
      //
      ...modificadosGruposAreas,
      ...nuevosAreas,
      ...modificadosAreas,
      ...eliminadosAreas,
      //
      ...nuevosPotenciales,
      ...modificadosPotenciales,
      ...eliminadosPotenciales,
    ];
    return data;
  };
  const headers = [
    {
      label: "Competencia",
      onOrder: false,
      onFilter: false,
    },
    {
      label: "Estado",
      onOrder: false,
      onFilter: false,
    },
  ];
  const CompetenciaRowComponent = ({ item }) => {
    const paddingCell = "p-4";
    const { nombre } = item;
    //
    return (
      <div className="table-row border-b border-gray-200">
        <div className={`table-cell ${paddingCell}`}>{`${nombre}`}</div>
        <div className={`table-cell ${paddingCell}`}>
          {item.estado}{" "}
          {item.cambios && item.cambios.length > 0 && (
            <span className="text-xs text-slate-500 uppercase">
              ({item.cambios.join(", ")})
            </span>
          )}
        </div>
      </div>
    );
  };
  const onSearchCambios = (e) => {
    let textValue = e.target.value;
    setSearchValue(textValue);
    textValue = textValue.toUpperCase();
    const cambiosFiltered = getData().filter((comp, cidx) =>
      comp.nombre.toUpperCase().includes(textValue)
    );
    setCambios(cambiosFiltered);
  };
  const actualizarCompetencias = () => {
    setShowAlert(false);
    dispatch(evaluacionActions.setCheckCompetencias(getData()));
    dispatch(evaluacionActions.setActualizarCompetencias());
  };
  //
  const [showAlert, setShowAlert] = useState(false);
  const onAceptarAlert = () => {
    const deseaActualizar = window.confirm(
      "Si no está seguro de cuáles evaluaciones se reiniciarán, por favor, contáctese con el soporte de Wel. ¿Desea continuar?"
    );
    if (deseaActualizar) {
      actualizarCompetencias();
    }
  };
  const onCancelarAlert = () => {
    setShowAlert(false);
  };
  const mostrarAlerta = () => {
    setShowAlert(true);
  };
  const getCustomAlertMessage = () => {
    let message = (
      <span>
        ¿Está seguro que desea actualizar las competencias de la evaluación?{" "}
        <br />
        Se perderán todos los formularios que incluyan los tipos de competencias
        a actualizar.
      </span>
    );
    return message;
  };
  //
  useEffect(() => {
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    dispatch(evaluacionActions.getCambiosCompetencias(idEvaluacion));
    dispatch(evaluacionActions.resetCheckCambiosCompetencias());
    setCambios(getData());
  }, []);

  useEffect(() => {
    setCambios(getData());
  }, [
    nuevos_generales.length,
    modificados_generales.length,
    eliminados_generales.length,
    modificados_grupos_areas.length,
    nuevos_areas.length,
    modificados_areas.length,
    eliminados_areas.length,
    modificados_grupos_cargos.length,
    nuevos_cargos.length,
    modificados_cargos.length,
    eliminados_cargos.length,
  ]);
  //
  return (
    <>
      <div className="flex items-center bg-white py-2 px-8 -mx-8 gap-8 border-b">
        <div
          onClick={() => navigate(`/evaluaciones/detail/${idEvaluacion}`)}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-lg">
          <span className="font-semibold">Cambios en Competencias:</span>{" "}
          {evaluacion.nombre}
        </div>
      </div>
      <div className="mt-4">
        <div className="px-4 py-2">
          <CustomTable
            headers={headers}
            data={Cambios}
            selected={getData()}
            RowComponent={CompetenciaRowComponent}
            // onCheckAll={onCheckAll}
            onActionButton={mostrarAlerta}
            onActionButtonLabel={"Actualizar"}
            onSearch={onSearchCambios}
            searchValue={SearchValue}
            loading={cambios_competencias_loading || actualizar_nomina_loading}
          ></CustomTable>
        </div>
      </div>
      <CustomAlert
        message={getCustomAlertMessage()}
        visible={showAlert}
        onAceptarHandler={onAceptarAlert}
        onCancelarHandler={onCancelarAlert}
      ></CustomAlert>
    </>
  );
}
export default EvaluacionCambiosCompetencias;
