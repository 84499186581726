import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckInput } from "src/modules/common/components/CheckInput";
import { evaluacionActions } from "../handlers/redux";

function EvaluadoresDefecto() {
  const dispatch = useDispatch();
  const initOptions = {
    jefe: false,
    paresChecked: false,
    pares: 0,
    subalternosChecked: false,
    subalternos: 0,
    autoevaluacion: false,
  };

  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const { tipo_evaluacion } = evaluacion;

  const [options, setOptions] = useState(initOptions);

  const onCheck =
    (field) =>
    ({ target }) => {
      let optionsCopy = { ...options, [field]: target.checked };
      //
      if (!optionsCopy.paresChecked) {
        optionsCopy.pares = 0;
      }
      if (!optionsCopy.subalternosChecked) {
        optionsCopy.subalternos = 0;
      }
      //
      setOptions(optionsCopy);
    };

  const onChange =
    (field) =>
    ({ target }) => {
      let optionsCopy = { ...options, [field]: target.value };
      setOptions(optionsCopy);
    };

  const onSubmit = () => {
    dispatch(evaluacionActions.setOpcionesPorDefecto(options));
  };

  return (
    <div className="flex flex-col gap-6 p-2">
      <div className="flex items-start gap-4">
        <CheckInput
          checked={options.jefe}
          onChange={onCheck("jefe")}
        ></CheckInput>
        <div>
          <div>Jefe directo</div>
          <div>
            Añade por defecto a los jefes directos de los colaboradores
            seleccionados
          </div>
        </div>
      </div>
      {/* LAS DEMÁS RELACIONES SON SOLO PARA EVALUACIONES DE TIPO DESEMPEÑO */}
      {tipo_evaluacion === 1 && (
        <>
          <div className="flex items-start gap-4">
            <CheckInput
              checked={options.paresChecked}
              onChange={onCheck("paresChecked")}
            ></CheckInput>
            <div>
              <div>Pares</div>
              <div>
                Añade por defecto a los pares de los colaboradores seleccionados
              </div>
              <div>
                Hasta{" "}
                <input
                  type="number"
                  className={`${
                    !options.paresChecked ? "bg-slate-200 " : ""
                  }border border-slate-300 rounded-md p-2 w-20`}
                  value={options.pares}
                  onChange={onChange("pares")}
                  disabled={!options.paresChecked}
                />{" "}
                pares
              </div>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <CheckInput
              checked={options.subalternosChecked}
              onChange={onCheck("subalternosChecked")}
            ></CheckInput>
            <div>
              <div>Subalternos</div>
              <div>
                Añade por defecto a los subalternos de los colaboradores
                seleccionados
              </div>
              <div>
                Hasta{" "}
                <input
                  type="number"
                  onChange={onChange("subalternos")}
                  className={`${
                    !options.subalternosChecked ? "bg-slate-200 " : ""
                  }border border-slate-300 rounded-md p-2 w-20`}
                  value={options.subalternos}
                  disabled={!options.subalternosChecked}
                />{" "}
                subalternos
              </div>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <CheckInput
              checked={options.autoevaluacion}
              onChange={onCheck("autoevaluacion")}
            ></CheckInput>
            <div>
              <div>Autoevaluación</div>
              <div>
                Añade por defecto un formulario de autoevalución a los
                colaboradores seleccionados
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <button
          onClick={onSubmit}
          className="bg-sky-500 p-2 text-white w-full rounded-md"
        >
          Aceptar
        </button>
      </div>
    </div>
  );
}

export default EvaluadoresDefecto;
