import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BarChart from "src/modules/common/components/BarChart";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { commonActions } from "src/modules/common/handler/redux";
import { ESTADOS_EVALUACION } from "src/modules/evaluacion/helpers/helpers";
import { colaboradorDesempenoActions } from "../handlers/redux";

function ColaboradorDesempenoPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idColaborador } = params;
  const colaborador_desempeno = useSelector(
    (state) => state.colaboradorDesempeno.colaborador_desempeno
  );
  const {
    promedio_evaluaciones = 0,
    ultima_evaluacion = {},
    promedios_evaluaciones = [],
  } = colaborador_desempeno;
  const {
    puntaje_ultima_evaluacion,
    clasificacion_ultima_evaluacion,
    fecha_ultima_evaluacion,
  } = ultima_evaluacion;
  const colaborador_desempeno_loading = useSelector(
    (state) => state.colaboradorDesempeno.colaborador_desempeno_loading
  );
  const colaborador_evaluaciones = useSelector(
    (state) => state.colaboradorDesempeno.colaborador_evaluaciones
  );
  const colaborador_evaluaciones_filter = useSelector(
    (state) => state.colaboradorDesempeno.colaborador_evaluaciones_filter
  );
  const { nombre: nombreEvaluacion, ordering } =
    colaborador_evaluaciones_filter;
  const colaborador_evaluaciones_loading = useSelector(
    (state) => state.colaboradorDesempeno.colaborador_evaluaciones_loading
  );
  const [anhosFiltro, setAnhosFiltro] = useState([]);
  const formatDate = (date) => {
    if (!date) {
      return "Sin fecha";
    }
    return dayjs(date.slice(0, 10)).format("DD/MM/YYYY");
  };
  const getYear = (date) => {
    if (!date) {
      return "";
    }
    return dayjs(date.slice(0, 10)).year();
  };
  //
  const ItemEvaluacionComponent = ({ item }) => {
    const goToEvaluacion = () => {
      navigate(`/evaluaciones/detail/${item.id}`);
    };
    const getEstadoLabel = () => {
      const { estado } = item;
      const { value, label } = estado;
      let labelClass = "bg-green-500  text-white";
      if (value === ESTADOS_EVALUACION.FINALIZADO) {
        labelClass = "bg-slate-300";
      }
      return <div className={`rounded-md p-1 ${labelClass}`}>{label}</div>;
    };
    //
    return (
      <>
        <TableRow>
          <div
            onClick={goToEvaluacion}
            className="flex gap-8 justify-between items-center text-sm hover:cursor-pointer"
          >
            <div className="flex items-center justify-between flex-1">
              <div className="flex flex-col gap-1 flex-1 w-1/3">
                <div className="font-bold">{item.nombre}</div>
                <div>Fecha inicio: {formatDate(item.fecha_inicio)}</div>
                <div>
                  Fecha finalización: {formatDate(item.fecha_finalizacion)}
                </div>
              </div>
              <div className="flex flex-col items-center flex-1 w-1/3">
                {getEstadoLabel()}
              </div>
              <div className="flex justify-end flex-1 w-1/3x">
                <div className="flex gap-1 rounded-md bg-slate-200 p-1">
                  <div>{item.puntaje_obtenido?.puntaje}% - </div>
                  <div>{item.puntaje_obtenido?.clasificacion}</div>
                </div>
              </div>
            </div>
            <div>
              <ChevronRight></ChevronRight>
            </div>
          </div>
        </TableRow>
      </>
    );
  };
  const onChangeEvaluacionText = (event) => {
    dispatch(
      colaboradorDesempenoActions.setColaboradorEvaluacionesFilter({
        id: idColaborador,
        nombre: event.target.value,
      })
    );
  };
  const getHistorialBarChartData = () => {
    let data = [];
    if (promedios_evaluaciones.length) {
      data = promedios_evaluaciones.map((p, idx) => ({
        x: idx + 1,
        y: p.puntaje,
        name: p.nombre,
      }));
    }
    !!process.env.REACT_APP_DEBUG &&
      console.log("getHistorialBarChartData", data);
    return data;
  };
  const getAnhos = () => {
    !!process.env.REACT_APP_DEBUG &&
      console.log("getAnhos", colaborador_evaluaciones);
    let anhos = [];
    const anhosEvaluaciones = colaborador_evaluaciones?.map((evaluacion, idx) =>
      getYear(evaluacion.fecha_finalizacion)
    );
    anhos = anhosEvaluaciones
      .filter((evalYear) => !!evalYear)
      .map((evalYear, idx) => ({
        value: evalYear,
        label: evalYear,
      }));
    const defaultOption = {
      value: 0,
      label: "Todos los años",
    };
    // eliminanos duplicados
    anhos = [...new Map(anhos.map((item) => [item["value"], item])).values()];
    anhos.unshift(defaultOption);
    //
    return anhos;
  };
  const filtrarPorAnho = (selectedOption) => {
    const anho = selectedOption.value;
    if (anho === 0) {
      dispatch(
        colaboradorDesempenoActions.setColaboradorEvaluacionesFilter({
          id: idColaborador,
        })
      );
      return;
    }
    dispatch(
      colaboradorDesempenoActions.setColaboradorEvaluacionesFilter({
        id: idColaborador,
        anho_finalizacion: anho,
      })
    );
  };
  //
  useEffect(() => {
    dispatch(commonActions.setEditDetailButton(null));
    dispatch(
      colaboradorDesempenoActions.getColaboradorDesempeno(idColaborador)
    );
    dispatch(
      colaboradorDesempenoActions.getColaboradorEvaluaciones({
        id: idColaborador,
      })
    );
    //
    setAnhosFiltro(getAnhos());
  }, []);
  //
  return (
    <div className="flex justify-center items-center w-full mt-10 pb-4">
      <div className="flex flex-col w-full md:w-5/6 gap-3">
        <div className="border-b pb-2 mb-4">
          <span className="font-bold text-lg">Resumen de desempeño</span>
        </div>
        {!colaborador_desempeno_loading && (
          <>
            {/* graficos */}
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-2 border border-zinc-200 rounded max-h-72 p-4 flex flex-wrap">
                <div className="text-md font-bold">
                  Historial de evaluaciones
                </div>
                <BarChart
                  // height={300}
                  // width={1200}
                  data={getHistorialBarChartData()}
                ></BarChart>
              </div>
              <div className="col-span-1 border border-zinc-200 rounded p-4 flex flex-col gap-2">
                <div className="text-md font-bold">Ultima evaluación</div>
                <div className="text-xs">
                  {formatDate(fecha_ultima_evaluacion)}
                </div>
                <div className="flex items-center justify-center">
                  <PercentageCircular
                    width={180}
                    height={180}
                    strokeWidth={9}
                    strokeColor={"red"}
                    value={puntaje_ultima_evaluacion}
                    content={
                      <>
                        <div className="font-bold text-2xl">
                          {puntaje_ultima_evaluacion?.toFixed(2)}%
                        </div>
                        <div className="text-sm">{`${clasificacion_ultima_evaluacion}`}</div>
                      </>
                    }
                  ></PercentageCircular>
                </div>
              </div>
              <div className="col-span-1 border border-zinc-200 rounded p-4 flex flex-col gap-2">
                <div className="text-md font-bold">Promedio de desempeño</div>
                {/* <div className="text-xs">Competencias: xx% | KPIs: xx%</div> */}
                <div className="flex items-center justify-center">
                  <PercentageCircular
                    width={180}
                    height={180}
                    strokeWidth={9}
                    strokeColor={"blue"}
                    value={promedio_evaluaciones}
                    content={
                      <>
                        <div className="font-bold text-2xl">
                          {promedio_evaluaciones?.toFixed(2)}%
                        </div>
                      </>
                    }
                  ></PercentageCircular>
                </div>
              </div>
            </div>
            {/* tabla */}
            <div className="flex flex-col gap-3 border border-zinc-200 rounded p-4">
              {/* items */}
              <div className="">
                <TableCustom
                  data={colaborador_evaluaciones}
                  ItemComponent={ItemEvaluacionComponent}
                  searchValue={nombreEvaluacion}
                  onChangeSearch={onChangeEvaluacionText}
                  tableTitle={"Histórico de Evaluaciones"}
                  hasChecks={false}
                  subject={""}
                  subjectGender={"F"}
                  addBtnClickHandler={null}
                  dropdownActions={null}
                  customHeaders={["Evaluaciones", "Estado", "Puntaje"]}
                  selectOptions={anhosFiltro}
                  onSelectChange={filtrarPorAnho}
                  actionSelectDefaulValue={true}
                />
              </div>
            </div>
          </>
        )}
        {/* Loading */}
        {!!colaborador_desempeno_loading && (
          <div className="w-full animate-pulse flex flex-col gap-4">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              <div className="col-span-1 md:col-span-6 border rounded-md bg-slate-300 h-72 w-full"></div>
              <div className="col-span-1 md:col-span-3 border rounded-md bg-slate-300 h-72 w-full"></div>
              <div className="col-span-1 md:col-span-3 border rounded-md bg-slate-300 h-72 w-full"></div>
            </div>
            <div className="border rounded-md bg-slate-300 w-full h-screen"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ColaboradorDesempenoPage;
