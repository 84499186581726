import { createSlice } from "@reduxjs/toolkit";

const competenciaPotencialSlice = createSlice({
  name: "competenciaPotencial",
  initialState: {
    competencias_potencial: false,
    competencias_potencial_loading: false,
    //
    competencias_potencial_edit: false,
    competencias_potencial_aux: false,
    aplicar_pesos_potenciales: false,
  },
  reducers: {
    setCompetenciasPotencial: (state, { payload = {} }) => {
      state.competencias_potencial = payload;
      state.competencias_potencial_loading = false;
    },
    getCompetenciasPotencial: (state, { payload = {} }) => {
      state.competencias_potencial_loading = true;
    },
    updateCompetenciasPotenciales: (state, { payload = {} }) => {
      state.competencias_potencial_loading = true;
    },
    setCompetenciasPotencialLoading: (state, { payload }) => {
      state.competencias_potencial_loading = payload;
    },
    //
    setCompetenciasPotencialEdit: (state, { payload }) => {
      state.competencias_potencial_edit = payload;
    },
    setCompetenciasPotencialAux: (state, { payload = {} }) => {
      state.competencias_potencial_aux = payload;
    },
    setAplicarPesosPotenciales: (state, { payload }) => {
      state.aplicar_pesos_potenciales = payload;
    },
  },
});

export const competenciaPotencialActions = competenciaPotencialSlice.actions;
export const competenciaPotencialReducer = competenciaPotencialSlice.reducer;
