import PercentageCircular from "src/modules/common/components/PercentageCircular";

const CardPercentageCircular = ({
  title,
  data,
  width,
  height,
  extraClassNamePuntaje = "",
}) => {
  return (
    <div className="border rounded-md p-4 flex flex-col gap-2 justify-center items-center">
      <div className="font-bold text-sm">{title}</div>
      <PercentageCircular
        width={width}
        height={height}
        strokeWidth={9}
        strokeColor={"blue"}
        value={data.puntaje || 0}
        content={
          <>
            <div className={`font-bold ${extraClassNamePuntaje}`}>
              {data.puntaje || 0}%
            </div>
            <div className="text-sm">{`${data.clasificacion}`}</div>
          </>
        }
      ></PercentageCircular>
    </div>
  );
};

export default CardPercentageCircular;
