import { competenciasAreaRoutes } from "src/modules/competencia/modules/area/handlers/routes";
import { competenciasCargoRoutes } from "src/modules/competencia/modules/cargo/handlers/routes";
import { competenciasGeneralRoutes } from "src/modules/competencia/modules/general/handlers/routes";
import { competenciasPotencialRoutes } from "../modules/potencial/handlers/routes";

export const competenciaRootRoutes = [
  ...competenciasGeneralRoutes,
  ...competenciasAreaRoutes,
  ...competenciasCargoRoutes,
  ...competenciasPotencialRoutes,
];
