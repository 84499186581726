import { id } from "date-fns/locale";
import dayjs from "dayjs";
import React from "react";
import { ChevronRight, PlusCircleFill } from "react-bootstrap-icons";
import userDefault from "src/modules/common/components/images/user_default.png";
import EtiquetaComponent from "../components/EtiquetaComponent";

/* 
  @param {Object} item: Datos del empleado con sus respuestas a cada pregunta de la encuesta.
  @param {Number} accessor: ID de la pregunta que se quiere mostrar su respuesta.
*/
const defaultContentRenderer = (item, accessor) => {
  // Buscar la respuesta que coincide con accessor
  const respuesta = item.respuestas.find((r) => r.pregunta === accessor);

  if (!respuesta) {
    return <div></div>;
  }

  // Renderizar según el tipo de respuesta
  if (
    respuesta.tipo_respuesta === "MULTIPLE" ||
    respuesta.tipo_respuesta === "SIMPLE"
  ) {
    return (
      <div className="flex flex-row gap-2">
        {respuesta.opciones_seleccionadas.length > 0
          ? respuesta.opciones_seleccionadas.map((opcion) => (
              <span
                key={opcion.id}
                className="border rounded-md px-1 bg-gray-200"
              >
                {opcion.texto}
              </span>
            ))
          : ""}
      </div>
    );
  } else if (respuesta.tipo_respuesta === "FECHA") {
    return (
      <div>
        {respuesta.respuesta_fecha
          ? dayjs(respuesta.respuesta_fecha).format("DD/MM/YYYY HH:mm")
          : ""}
      </div>
    );
  } else if (respuesta.tipo_respuesta === "TEXTO") {
    return (
      <div>
        {respuesta.respuesta_texto
          ? respuesta.respuesta_texto.length > 50
            ? `${respuesta.respuesta_texto.substring(0, 50)}...`
            : respuesta.respuesta_texto
          : ""}
      </div>
    );
  } else {
    return <div>Tipo de respuesta desconocido</div>;
  }
};

// Construir la configuración dinámica de DataTable
export const buildDatatableConfig = (
  preguntas,
  etiquetas,
  onRowClick,
  onSelectEtiquetas,
  setFiltering
) => {
  const colaboradorColumn = {
    title: "Colaborador",
    contentClass: "px-2 py-4",
    // accessor: "empleado",
    contentRenderer: (item) => (
      <div
        className="flex items-center cursor-pointer gap-2 w-[250px]"
        onClick={() => onRowClick(item)}
      >
        <img
          src={item.empleado.foto_empleado || userDefault}
          className="h-12 w-12 rounded"
        />
        <div className="flex flex-col flex-1">
          <p className="font-semibold whitespace-normal">
            {item.empleado.nombre} {item.empleado.apellido}
          </p>
          <p className="text-sm whitespace-normal">
            {item.empleado.cargo ? item.empleado.cargo.nombre : "Sin cargo"}
          </p>
        </div>
        <ChevronRight size={16} className="text-sky-500" />
      </div>
    ),
  };

  const columns = preguntas.map((column) => {
    let columnConfig = {
      title: column.texto,
      contentClass: "px-2 py-4",
      accessor: column.id,
      // onOrder: setOrdering(column.field),
      // El filtro se le agrega a los que son de tipo selección nada más por el momento.
      onFilter:
        column.tipo_respuesta === "MULTIPLE" ||
        column.tipo_respuesta === "SIMPLE" ||
        column.tipo_respuesta === "TEXTO"
          ? setFiltering
          : null,
      // El filtro por opciones son para los que sea su respuesta de tipo selección.
      filterWithOptions:
        column.tipo_respuesta === "MULTIPLE" ||
        column.tipo_respuesta === "SIMPLE",
      // ReactSelect tiene que recibir las opciones en un array de objetos con claves value y label.
      optionsFilter:
        column.tipo_respuesta === "MULTIPLE" ||
        column.tipo_respuesta === "SIMPLE"
          ? column.opciones.map((opcion) => ({
              value: opcion.id,
              label: opcion.texto,
            }))
          : [],
    };

    // Personalizar contentRenderer solo para campos específicos
    columnConfig.contentRenderer = (item) =>
      defaultContentRenderer(item, column.id);

    return columnConfig;
  });

  const etiquetasColumn = {
    title: "Etiquetas",
    contentClass: "px-2 py-4",
    // accessor: "empleado",
    onFilter: setFiltering,
    filterWithOptions: true,
    optionsFilter: etiquetas.map((opcion) => ({
      value: opcion.id,
      label: opcion.nombre,
    })),
    contentRenderer: (item) => (
      <div className="flex flex-col justify-center gap-2">
        <button
          className="text-sky-200 hover:text-sky-300"
          title="Asignar etiquetas"
          onClick={() => onSelectEtiquetas(item)}
        >
          <PlusCircleFill size={18} />
        </button>
        {item.etiquetas.length > 0 && (
          <div className="flex flex-row gap-2">
            {item.etiquetas.map((etiqueta) => (
              <EtiquetaComponent
                key={etiqueta.id}
                name={etiqueta.nombre}
                color={etiqueta.color}
                asignacion_data={etiqueta.asignacion_data}
              />
            ))}
          </div>
        )}
      </div>
    ),
  };

  return {
    headers: [colaboradorColumn, ...columns, etiquetasColumn],
    rowActions: [],
  };
};
