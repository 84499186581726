const TabButton = ({ title, isActive, action }) => {
  return (
    <button
      className={
        isActive
          ? `text-xs rounded px-2 py-1 bg-zinc-100 border border-sky-500 active`
          : `text-xs rounded px-2 py-1 bg-zinc-100 border border-zinc-100`
      }
      onClick={action}
    >
      {title}
    </button>
  );
};

export default TabButton;
