import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "src/modules/common/components/CustomTable";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import { evaluacionActions } from "../handlers/redux";
import CustomAlert from "src/modules/common/components/CustomAlert";

function EvaluacionCambiosNomina() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { idEvaluacion } = params;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const [Cambios, setCambios] = useState([]);
  const [SearchValue, setSearchValue] = useState("");
  const cambios_nomina = useSelector(
    (state) => state.evaluacion.cambios_nomina
  );
  const cambios_nomina_checked = useSelector(
    (state) => state.evaluacion.cambios_nomina_checked
  );
  const cambios_nomina_loading = useSelector(
    (state) => state.evaluacion.cambios_nomina_loading
  );
  const actualizar_nomina_loading = useSelector(
    (state) => state.evaluacion.actualizar_nomina_loading
  );
  const { modificados = [], nuevos = [] } = cambios_nomina;

  const getData = () => {
    let data = [];
    const newModificados = modificados.map((item, idx) => ({
      ...item,
      estado: "Modificado",
    }));
    const newNuevos = nuevos.map((item, idx) => ({ ...item, estado: "Nuevo" }));
    data = [...newModificados, ...newNuevos];
    return data;
  };
  const headers = [
    {
      label: "Colaborador",
      onOrder: false,
      onFilter: false,
    },
    {
      label: "Estado",
      onOrder: false,
      onFilter: false,
    },
  ];
  const onCheckAll = (e) => {
    const checkValue = e.target.checked;
    getData().forEach((colab, cidx) =>
      dispatch(
        evaluacionActions.checkCambioNomina({
          colaborador: colab,
          checked: checkValue,
        })
      )
    );
  };
  const ColaboradorRowComponent = ({ item }) => {
    const dispatch = useDispatch();
    const paddingCell = "p-4";
    const { nombre, apellido } = item;
    const onCheckColaborador = (e) => {
      const checkValue = e.target.checked;
      console.log("onCheckColaborador", checkValue, item.id);
      dispatch(
        evaluacionActions.checkCambioNomina({
          colaborador: item,
          checked: checkValue,
        })
      );
    };
    //
    return (
      <div className="table-row border-b border-gray-200">
        <div className={`table-cell p-2`}>
          <input
            type="checkbox"
            name={`check-${item.id}`}
            id={`check-${item.id}`}
            onChange={onCheckColaborador}
            checked={cambios_nomina_checked
              .map((cnc, cnci) => cnc.id)
              .includes(item.id)}
          />
        </div>
        <div
          className={`table-cell ${paddingCell}`}
        >{`${nombre} ${apellido}`}</div>
        <div className={`table-cell ${paddingCell}`}>
          {item.estado}{" "}
          {item.cambios && (
            <span className="text-xs text-slate-500 uppercase">
              ({item.cambios.join(", ")})
            </span>
          )}
        </div>
      </div>
    );
  };
  const onSearchCambios = (e) => {
    let textValue = e.target.value;
    setSearchValue(textValue);
    textValue = textValue.toUpperCase();
    const cambiosFiltered = getData().filter(
      (colab, cidx) =>
        colab.nombre.toUpperCase().includes(textValue) ||
        colab.apellido.toUpperCase().includes(textValue)
    );
    setCambios(cambiosFiltered);
  };
  const actualizarNomina = () => {
    setShowAlert(false);
    if (cambios_nomina_checked.length < 1) {
      ErrorToast({ message: "Debe seleccionar al menos un colaborador" });
      return;
    }
    dispatch(evaluacionActions.setActualizarNomina());
  };
  //
  const [showAlert, setShowAlert] = useState(false);
  const onAceptarAlert = () => {
    const deseaActualizar = window.confirm(
      "Si no está seguro de cuáles evaluaciones se reiniciarán, por favor, contáctese con el soporte de Wel. ¿Desea continuar?"
    );
    if (deseaActualizar) {
      actualizarNomina();
    }
  };
  const onCancelarAlert = () => {
    setShowAlert(false);
  };
  const mostrarAlerta = () => {
    setShowAlert(true);
  };
  const getCustomAlertMessage = () => {
    let message =
      "Está seguro que desea actualizar la nómina de la evaluación con los colaboradores seleccionados?";
    cambios_nomina_checked.filter((cnc, cnci) => cnc.estado === "Modificado");
    if (cambios_nomina_checked.length > 0) {
      message = `Se podrían perder formularios que incluyan a los colaboradores modificados. ¿Desea continuar?`;
    }
    return message;
  };
  //
  useEffect(() => {
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    dispatch(evaluacionActions.getCambiosNomina(idEvaluacion));
    dispatch(evaluacionActions.resetCheckCambiosNomina());
    setCambios(getData());
  }, []);

  useEffect(() => {
    setCambios(getData());
  }, [modificados.length, nuevos.length]);
  //
  return (
    <>
      <div className="flex items-center bg-white py-2 px-8 -mx-8 gap-8 border-b">
        <div
          onClick={() => navigate(`/evaluaciones/detail/${idEvaluacion}`)}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-lg">
          <span className="font-semibold">Cambios en Nómina:</span>{" "}
          {evaluacion.nombre}
        </div>
      </div>
      <div className="mt-4">
        <div className="px-4 py-2">
          <CustomTable
            headers={headers}
            data={Cambios}
            selected={cambios_nomina_checked}
            RowComponent={ColaboradorRowComponent}
            onCheckAll={onCheckAll}
            onActionButton={mostrarAlerta}
            onActionButtonLabel={"Actualizar"}
            onSearch={onSearchCambios}
            searchValue={SearchValue}
            loading={cambios_nomina_loading || actualizar_nomina_loading}
          ></CustomTable>
        </div>
      </div>
      <CustomAlert
        message={getCustomAlertMessage()}
        visible={showAlert}
        onAceptarHandler={onAceptarAlert}
        onCancelarHandler={onCancelarAlert}
      ></CustomAlert>
    </>
  );
}
export default EvaluacionCambiosNomina;
