import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import { CompetenciaPieChart } from "src/modules/competencia/modules/common/components/CompetenciaPieChart.";
import PlanAvanzadoAlert from "src/modules/competencia/modules/common/components/PlanAvanzadoAlert";
import PesoInput from "../components/PesoInput";
import { evaluacionActions } from "../handlers/redux";

const pesosDesempeno = [
  {
    title: "Competencias generales",
    description: "Aplica a toda la organización",
    dotColorClass: "bg-sky-500",
    field: "peso_generales",
  },
  {
    title: "Competencias por áreas",
    description: "Aplica a las áreas",
    dotColorClass: "bg-green-500",
    field: "peso_areas",
  },
  {
    title: "Competencias por cargos",
    description: "Aplica a los cargos",
    dotColorClass: "bg-red-500",
    field: "peso_cargos",
  },
  {
    title: "KPIs",
    description: "Aplica a los KPIs",
    dotColorClass: "bg-yellow-500",
    field: "peso_kpis",
  },
  {
    title: "Objetivos por cargo",
    description: "Aplica a los objetivos por cargo",
    dotColorClass: "bg-orange-500",
    field: "peso_objetivos_cargo",
  },
];

const pesosPotencial = [
  {
    title: "Competencias de potencial",
    description: "Aplica a toda la organización",
    dotColorClass: "bg-sky-500",
    field: "peso_potenciales",
  },
  {
    title: "KPIs",
    description: "Aplica a los KPIs",
    dotColorClass: "bg-yellow-500",
    field: "peso_kpis",
  },
  {
    title: "Objetivos por cargo",
    description: "Aplica a los objetivos por cargo",
    dotColorClass: "bg-orange-500",
    field: "peso_objetivos_cargo",
  },
];

const checkTodosLosPesosIguales = (configuracion, tipo_evaluacion) => {
  const {
    id,
    peso_generales,
    peso_areas,
    peso_cargos,
    peso_kpis,
    peso_objetivos_cargo,
    peso_potenciales,
  } = configuracion;
  if (id) {
    // Verificar si se aplicaron pesos desiguales
    const pesosDesempenoHabilitados = [
      peso_generales,
      peso_areas,
      peso_cargos,
      peso_kpis,
      peso_objetivos_cargo,
    ];
    const pesosPotencialHabilitados = [
      peso_potenciales,
      peso_kpis,
      peso_objetivos_cargo,
    ];
    let pesosHabilitados =
      tipo_evaluacion === 1
        ? pesosDesempenoHabilitados
        : pesosPotencialHabilitados;
    pesosHabilitados = pesosHabilitados.filter((peso) => !!peso);
    const sumaPesos = pesosHabilitados.reduce(
      (acc, peso) => (acc += parseFloat(peso)),
      0
    );
    const promedioPesos = pesosHabilitados.length
      ? sumaPesos / pesosHabilitados.length
      : 0;

    const todosLosPesosIguales =
      pesosHabilitados.filter((peso, pidx) => peso !== promedioPesos).length ===
      0;

    return todosLosPesosIguales;
  }
};

const getInitialConfiguracionEdit = (configuracion) => {
  const { id } = configuracion;
  const fields = [
    "peso_generales",
    "peso_areas",
    "peso_cargos",
    "peso_kpis",
    "peso_objetivos_cargo",
    "peso_potenciales",
  ];
  const configuracionCopy = { ...configuracion };
  if (id) {
    fields.forEach((field) => {
      if (configuracionCopy[field]) {
        configuracionCopy[`${field}_checked`] = true;
      }
    });
    return configuracionCopy;
  }
  return configuracion;
};

function ConfiguracionFormularioForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion } = params;

  const empresa = useSelector((state) => state.empresa.empresa);
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacion_loading = useSelector(
    (state) => state.evaluacion.evaluacion_loading
  );
  const configuracion = useSelector((state) => state.evaluacion.configuracion);
  const configuracion_loading = useSelector(
    (state) => state.evaluacion.configuracion_loading
  );

  const [pesos, setPesos] = useState([]);
  const [aplicarPesos, setAplicarPesos] = useState(false);

  useEffect(() => {
    dispatch(commonActions.showSidebar(false));
    dispatch(empresaActions.empresa());
    dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    dispatch(
      evaluacionActions.setConfiguracion(
        getInitialConfiguracionEdit(configuracion)
      )
    );
    //
    if (configuracion?.id && evaluacion?.tipo_evaluacion)
      setAplicarPesos(
        !checkTodosLosPesosIguales(configuracion, evaluacion.tipo_evaluacion)
      );
  }, []);

  useEffect(() => {
    if (evaluacion) {
      setPesos(
        evaluacion.tipo_evaluacion === 1 ? pesosDesempeno : pesosPotencial
      );
    }
  }, [evaluacion]);

  const onGoBack = () => {
    dispatch(commonActions.setSelectModal({ open: false }));
    navigate(`/evaluaciones/detail/${idEvaluacion}`);
  };
  const tienePlanAvanzado = () => {
    return empresa.tipo_plan === "Avanzado";
  };
  const onAplicarPesoCheck = (e) => {
    setAplicarPesos(e.target.checked);
  };
  const onChange = (field) => (e) => {
    const value = e.target?.value;
    dispatch(
      evaluacionActions.setConfiguracion({
        ...configuracion,
        [field]: value,
      })
    );
  };
  const onChangeCheck = (field, valueField) => (e) => {
    const checked = e.target?.checked;
    let configuracionCopy = {
      ...configuracion,
      [field]: checked,
      [valueField]: 0,
    };
    if (!aplicarPesos) {
      configuracionCopy = {
        ...configuracionCopy,
        peso_generales: 0,
        peso_areas: 0,
        peso_cargos: 0,
        peso_kpis: 0,
        peso_objetivos_cargo: 0,
        peso_potenciales: 0,
      };
    }
    dispatch(evaluacionActions.setConfiguracion(configuracionCopy));
  };

  const onSubmit = (e) => {
    const {
      peso_generales = 0,
      peso_areas = 0,
      peso_cargos = 0,
      peso_kpis = 0,
      peso_objetivos_cargo = 0,
      peso_potenciales = 0,
      nombre,
      peso_generales_checked = false,
      peso_areas_checked = false,
      peso_cargos_checked = false,
      peso_kpis_checked = false,
      peso_objetivos_cargo_checked = false,
      peso_potenciales_checked = false,
    } = configuracion;
    const { tipo_evaluacion } = evaluacion;
    let hasErrors = false;
    console.log("configuracion", configuracion);
    // Para evaluaciones de tipo desempeño y tipo potencial son distintos los pesos que se deben controlar
    const suma =
      tipo_evaluacion === 1
        ? parseFloat(peso_generales || 0) +
          parseFloat(peso_areas || 0) +
          parseFloat(peso_cargos || 0) +
          parseFloat(peso_kpis || 0) +
          parseFloat(peso_objetivos_cargo || 0)
        : parseFloat(peso_potenciales || 0) +
          parseFloat(peso_kpis || 0) +
          parseFloat(peso_objetivos_cargo || 0);
    !!process.env.REACT_APP_DEBUG && console.log("suma", suma);
    if (
      (tipo_evaluacion === 1 &&
        !peso_generales_checked &&
        !peso_areas_checked &&
        !peso_cargos_checked &&
        !peso_kpis_checked &&
        !peso_objetivos_cargo_checked) ||
      (tipo_evaluacion === 2 &&
        !peso_potenciales_checked &&
        !peso_kpis_checked &&
        !peso_objetivos_cargo_checked)
    ) {
      ErrorToast({ message: "Debe elegir al menos una opción" });
      hasErrors = true;
    }
    if (suma !== 0 && suma !== 100) {
      ErrorToast({ message: "La suma de los pesos debe ser 100%" });
      hasErrors = true;
    }
    if (!nombre) {
      ErrorToast({ message: "Debe ingresar un nombre" });
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
    if (configuracion?.id) {
      dispatch(evaluacionActions.updateConfiguracion());
    } else {
      dispatch(evaluacionActions.createConfiguracion());
    }

    //
  };
  //
  return (
    <>
      <div className="flex items-center bg-white py-2 px-8 -mx-8 gap-8 border-b">
        <div
          onClick={onGoBack}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-lg">
          Configuración de formulario
        </div>
      </div>
      {/* body */}
      {!evaluacion_loading && (
        <div className="flex flex-col items-center mt-4">
          {!configuracion_loading && (
            <form className="flex flex-col w-full md:w-4/6 gap-3">
              <div className="flex justify-between items-center text-lg font-bold border-b py-2">
                <div>Configuracion inicial</div>
              </div>
              {/*  */}
              <div className="flex flex-col">
                <label className="font-semibold" htmlFor="nombre_formulario">
                  Nombre del formulario:
                </label>
                <input
                  id="nombre_formulario"
                  type="text"
                  className="border p-2 rounded-md"
                  placeholder="Sin especificar..."
                  value={configuracion.nombre}
                  onChange={onChange("nombre")}
                />
              </div>
              <div className="flex flex-col gap-2 border-b">
                <h3 className="font-semibold text-lg">Tipo de evaluación</h3>
                <p className="text-sm text-slate-400 m-0 pb-3">
                  Seleccione la configuración de evaluación para este
                  formulario:
                </p>
              </div>
              {/*  */}
              <div className="flex flex-row gap-2 items-center">
                {!!tienePlanAvanzado() && (
                  <>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="m-0 p-0 h-4 w-4 accent-sky-600"
                        checked={aplicarPesos}
                        onChange={onAplicarPesoCheck}
                      />
                    </div>
                    <div className="font-bold text-sm">
                      Aplicar pesos a las competencias
                    </div>
                  </>
                )}
              </div>
              {/* GRÁFICO */}
              <div className="flex flex-col items-center gap-2">
                {!!tienePlanAvanzado() && !!aplicarPesos && (
                  <div className="">
                    <CompetenciaPieChart
                      competencias={[
                        {
                          nombre: "Group A",
                          peso: parseInt(configuracion.peso_generales),
                          isChecked: configuracion.peso_generales_checked,
                        },
                        {
                          nombre: "Group B",
                          peso: parseInt(configuracion.peso_areas),
                          isChecked: configuracion.peso_areas_checked,
                        },
                        {
                          nombre: "Group C",
                          peso: parseInt(configuracion.peso_cargos),
                          isChecked: configuracion.peso_cargos_checked,
                        },
                        {
                          nombre: "Group D",
                          peso: parseInt(configuracion.peso_kpis),
                          isChecked: configuracion.peso_kpis_checked,
                        },
                        {
                          nombre: "Group E",
                          peso: parseInt(configuracion.peso_objetivos_cargo),
                          isChecked: configuracion.peso_objetivos_cargo_checked,
                        },
                      ].filter((p, i) => p.isChecked)}
                    />
                  </div>
                )}
                {!tienePlanAvanzado() && (
                  <PlanAvanzadoAlert></PlanAvanzadoAlert>
                )}
              </div>
              {/*  */}
              {pesos.map((peso, idx) => (
                <PesoInput
                  key={`peso-input-${idx}`}
                  title={peso.title}
                  description={peso.description}
                  dotColorClass={peso.dotColorClass}
                  value={configuracion[peso.field]}
                  onChangeInput={onChange(peso.field)}
                  checked={configuracion[`${peso.field}_checked`]}
                  onChangeCheck={onChangeCheck(
                    `${peso.field}_checked`,
                    peso.field
                  )}
                  inputIsVisible={!!aplicarPesos}
                />
              ))}
              {/* <div className="my-8 border border-[A85F5C] bg-[#EFDFDE] py-3 px-4 rounded-md text-[#9D4B47]">
            La suma de los pesos deben equivaler a 100%
          </div> */}
              <div className="mb-4 mt-2">
                <button
                  onClick={onSubmit}
                  type="button"
                  className="bg-sky-500 w-full p-3 rounded-md text-white"
                >
                  Guardar configuracion
                </button>
              </div>
            </form>
          )}
          {!!configuracion_loading && (
            <div className="flex justify-center items-center w-full h-80">
              <LoadingSpinButton
                className="justify-center"
                message="Enviando, por favor espere..."
              ></LoadingSpinButton>
            </div>
          )}
        </div>
      )}
      {!!evaluacion_loading && (
        <div className="flex justify-center h-full items-center mt-24">
          <LoadingSpinButton />
        </div>
      )}
    </>
  );
}
export default ConfiguracionFormularioForm;
