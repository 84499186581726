import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { empresaApi } from "./api";
import { empresaActions } from "./redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";

function* empresaUpdateSaga({ payload }) {
  const token = getToken();
  const empresa = yield select((state) => state.empresa.empresa);

  const params = { ...empresa };
  try {
    const data = yield call(empresaApi(token).update, params);
    //
    yield put(empresaActions.empresaUpdateSuccess());
    yield put(empresaActions.empresa());
  } catch (error) {
    yield put(empresaActions.empresaUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* empresaUpdateLogoSaga({ payload }) {
  const token = getToken();
  const empresa = yield select((state) => state.empresa.empresa);

  const params = { ...empresa };
  try {
    const data = yield call(empresaApi(token).logoUpdate, params);
    //
    yield put(empresaActions.empresaUpdateSuccess());
    yield put(empresaActions.empresa());
  } catch (error) {
    yield put(empresaActions.empresaUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* empresaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).get);
    yield put(empresaActions.setEmpresa({ empresa: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* rubrosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).rubrolist);
    yield put(empresaActions.setRubros({ rubros: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* habilitarEdicionKpisSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).habilitarEdicionKpis, payload);
    yield put(empresaActions.empresa());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* habilitarEdicionObjetivosCargoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).habilitarEdicionObjetivosCargo, payload);
    yield put(empresaActions.empresa());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Error al cambiar permiso de edición de Objetivos del cargo de parte de los mismos colaboradores." })
  }
}

function* UpdateAprobacionPermisosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      empresaApi(token).updateAprobacionPermisos,
      payload
    );
    yield put(empresaActions.empresa());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* limitarVisibilidadPlanCarreraSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      empresaApi(token).limitarVisibilidadPlanCarrera,
      payload
    );
    yield put(empresaActions.empresa());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* deshabilitarEdicionKpisSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).deshabilitarEdicionKpis, payload);
    yield put(empresaActions.empresa());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* deshabilitarEdicionObjetivosCargoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).deshabilitarEdicionObjetivosCargo, payload);
    yield put(empresaActions.empresa());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Error al cambiar permiso de creación y modificación de Objetivos del cargo por parte de Líderes." })
  }
}

function* habilitarSuperarPuntajeEvaluacionSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      empresaApi(token).habilitarSuperarPuntajeEvaluacion,
      payload
    );
    yield put(empresaActions.empresa());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* setPermisosEmpresaSaga({ payload }) {
  const token = getToken();
  // console.log("setPermisosEmpresaSaga", payload);
  // return;
  try {
    yield call(empresaApi(token).cambiarPermisos, payload);
    yield put(empresaActions.empresa());
  } catch (error) {
    console.error("Error cambiando permisos:", error);
    // Manejo de errores si es necesario
  }
}

function* empresaLaboralSaga() {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).empresaLaboral);
    yield put(empresaActions.setEmpresaLaboral(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error." });
  }
}

function* createHorasPorDiaSaga({ payload }) {
  // Crea las horas por día de la empresa actual
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).createHorasPorDia, payload);
    yield put(empresaActions.getEmpresaLaboral());
    SuccessToast({ message: "Horas por día creados con exito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error." });
  }
}

function* salarioMinimoUpdateSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).salarioMinimoUpdate, payload);
    yield put(empresaActions.getEmpresaLaboral());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error." });
  }
}

export function* empresaSagas() {
  // update
  yield takeEvery(empresaActions.empresaUpdate.type, empresaUpdateSaga);
  yield takeEvery(empresaActions.empresaUpdateLogo.type, empresaUpdateLogoSaga);
  // list
  yield takeEvery(empresaActions.empresa.type, empresaSaga);
  yield takeEvery(empresaActions.rubros.type, rubrosSaga);
  //
  yield takeEvery(
    empresaActions.setHabilitarEdicionKpis.type,
    habilitarEdicionKpisSaga
  );
  yield takeEvery(
    empresaActions.setHabilitarEdicionObjetivosCargo.type,
    habilitarEdicionObjetivosCargoSaga
  );
  yield takeEvery(
    empresaActions.updateAprobacionPermisos.type,
    UpdateAprobacionPermisosSaga
  );
  yield takeEvery(
    empresaActions.setVisibilidadPlanCarrera.type,
    limitarVisibilidadPlanCarreraSaga
  );
  //
  yield takeEvery(
    empresaActions.setDeshabilitarEdicionKpis.type,
    deshabilitarEdicionKpisSaga
  );
  yield takeEvery(
    empresaActions.setDeshabilitarEdicionObjetivosCargo.type,
    deshabilitarEdicionObjetivosCargoSaga
  );
  yield takeEvery(
    empresaActions.setHabilitarSuperarPuntajeEvaluacion.type,
    habilitarSuperarPuntajeEvaluacionSaga
  );
  yield takeEvery(
    empresaActions.setPermisosEmpresa.type,
    setPermisosEmpresaSaga
  );
  //
  yield takeEvery(empresaActions.getEmpresaLaboral.type, empresaLaboralSaga);
  yield takeEvery(empresaActions.createHorasPorDia.type, createHorasPorDiaSaga);
  yield takeEvery(
    empresaActions.updateSalarioMinimo.type,
    salarioMinimoUpdateSaga
  );
}
