export const puntuacionesDefault = [
  {
    id: 1,
    nombre: "Siempre",
    porcentaje: 100,
    descripcion:
      "En todas las ocasiones se observa esta conducta. Equivalente a excelente.",
  },
  {
    id: 2,
    nombre: "Constantemente",
    porcentaje: 75,
    descripcion: "La mayoría de las veces se observa. Equivalente a muy buena",
  },
  {
    id: 3,
    nombre: "Frecuentemente",
    porcentaje: 50,
    descripcion: "Algunas veces se observa esta conducta. Equivalente a buena",
  },
  {
    id: 4,
    nombre: "En Ocasiones",
    porcentaje: 25,
    descripcion: "Pocas veces se observa la conducta. Equivalente a regular",
  },
  {
    id: 5,
    nombre: "Nunca",
    porcentaje: 0,
    descripcion: "No se observa la conducta.",
  },
  // { label: "No aplica", value: -1 },
];

export const RELACIONES = {
  AUTOEVALUACION: "Autoevaluación",
  JEFE_DIRECTO: "Jefe directo",
  PAR: "Par",
  SUBALTERNO: "Subalterno",
  SIN_ESPECIFICAR: "Sin especificar",
  SIN_EVALUADOR: "Sin evaluador",
};

export const DESCRIPCIONES_NINEBOX = [
  "Este cuadrante agrupa a los colaboradores más valiosos para la organización, aquellos con un desempeño sobresaliente y un alto potencial para asumir responsabilidades mayores. No solo cumplen con sus objetivos, sino que los superan consistentemente, mostrando habilidades excepcionales de liderazgo, innovación y trabajo en equipo. Estos empleados son ideales para ocupar roles clave en la sucesión de liderazgo o para liderar proyectos estratégicos. Retener a este talento es una prioridad, ya que representan el futuro de la organización. Se recomienda asignarles desafíos significativos y oportunidades de desarrollo para mantenerlos motivados y comprometidos.",
  "Aquí se encuentran colaboradores que muestran un gran potencial, pero que todavía están en etapas iniciales de desarrollo o adaptación a sus roles actuales. Es posible que aún no estén alcanzando los niveles esperados de desempeño, pero su disposición para aprender, su actitud positiva y su capacidad para adquirir nuevas competencias los hacen una inversión prometedora para la empresa. Este grupo requiere planes de desarrollo personalizados, incluyendo capacitación, coaching y seguimiento constante para ayudarlos a crecer y alcanzar su máximo rendimiento.",
  "Los colaboradores en este cuadrante ya están generando resultados significativos, aunque su desempeño aún tiene margen de mejora. Sin embargo, su alto potencial indica que pueden crecer rápidamente si reciben el apoyo adecuado. Estos empleados tienen la capacidad de asumir roles estratégicos en el mediano plazo, siempre que se trabaje en fortalecer sus habilidades actuales. Es importante invertir en su formación, asignarles mentores y proporcionarles experiencias retadoras que les permitan alcanzar su máximo desempeño.",
  "Los colaboradores en este cuadrante tienen un potencial alto, pero su desempeño actual no refleja ese potencial. Puede deberse a barreras específicas, como una falta de motivación, desafíos personales, problemas de alineación con su rol actual o la necesidad de apoyo adicional por parte de sus líderes. Este grupo requiere un análisis más profundo para identificar las causas del bajo rendimiento y determinar si pueden ser superadas. Con el enfoque correcto, estos empleados pueden convertirse en piezas clave para la empresa.",
  "Este cuadrante representa el grupo más estratégico para la empresa. Son colaboradores que combinan un desempeño medio con un potencial de crecimiento, lo que los convierte en activos fundamentales para el éxito a largo plazo. Además de cumplir con sus objetivos, destacan por su capacidad de influir positivamente en sus equipos y de aportar valor estratégico en diferentes niveles. Invertir en su desarrollo y diseñar planes de carrera a la medida es crucial para retenerlos, ya que otras organizaciones también pueden competir por atraer este tipo de talento.",
  "Este cuadrante está compuesto por colaboradores con un desempeño sólido y constante, pero con un potencial limitado para asumir mayores responsabilidades o roles estratégicos. Son el “colchón de estabilidad” de la empresa, quienes garantizan que las operaciones se mantengan en marcha de manera eficiente. Aunque no son candidatos para promociones, son altamente valiosos en sus roles actuales. Es importante reconocer su esfuerzo y mantenerlos motivados a través de programas de reconocimiento y pequeñas oportunidades de crecimiento dentro de su área.",
  "Los colaboradores en este cuadrante tienen un desempeño adecuado o promedio, pero su potencial de crecimiento es bajo o incierto. Es importante evaluar si pueden ser más efectivos con apoyo adicional o si están en roles que no se ajustan a sus habilidades. En algunos casos, podrían necesitar un cambio de posición o una evaluación más detallada para determinar si pueden ser aprovechados en otras áreas. Si no se logra mejorar su rendimiento, podrían representar un costo de oportunidad para la empresa.",
  "Este cuadrante agrupa a colaboradores con un desempeño constante y satisfactorio, aunque con un potencial limitado. Son efectivos y confiables en sus roles actuales, y la empresa depende de ellos para mantener los estándares de productividad y calidad. Aunque no suelen ser candidatos para promociones, su contribución es esencial para el funcionamiento diario de la empresa. Mantener su compromiso a través de pequeños reconocimientos, aumentos salariales o ajustes en su carga laboral puede ser clave para asegurar su permanencia.",
  "Este cuadrante identifica a los colaboradores con el desempeño más bajo y con un potencial limitado para crecer dentro de la empresa. Su bajo rendimiento puede estar afectando al equipo o a la empresa en general. En estos casos, se requiere una acción rápida y estratégica: brindar retroalimentación clara, establecer un plan de mejora a corto plazo y, si no se observan cambios positivos, considerar la posibilidad de desvinculación. Manejar este proceso con respeto y transparencia es fundamental para minimizar el impacto en el equipo y la cultura organizacional.",
];
