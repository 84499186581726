import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";

function CompetenciasLayout({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.setTitle("Competencias"));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "General",
          to: "/competencias/general",
        },
        {
          name: "Area",
          to: "/competencias/area",
        },
        {
          name: "Cargo",
          to: "/competencias/cargo",
        },
        {
          name: "Potencial",
          to: "/competencias/potencial",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);

  return <>{children}</>;
}

export default CompetenciasLayout;
