import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { commonActions } from "src/modules/common/handler/redux";
import { getToken, ObjectToString } from "src/utils/helpers";
import { evaluacionApi } from "./api";
import { evaluacionActions } from "./redux";

function* evaluacionesSaga({ payload }) {
  const token = getToken();
  const tipo_evaluacion = yield select(
    (state) => state.evaluacion.tipo_evaluacion_actual
  );
  const evaluaciones_filter = yield select(
    (state) => state.evaluacion.evaluaciones_filter
  );
  try {
    const data = yield call(evaluacionApi(token).list, {
      tipo_evaluacion,
      evaluaciones_filter,
    });
    //
    yield put(evaluacionActions.setEvaluaciones(data.results));
    yield put(evaluacionActions.setEvaluacionesCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* evaluacionDetailSaga({ payload }) {
  const token = getToken();
  try {
    if (!payload.id) {
      return;
    }
    const data = yield call(evaluacionApi(token).detail, payload);
    yield put(evaluacionActions.setEvaluacion(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* evaluacionCambiosSaga({ payload }) {
  const token = getToken();
  try {
    if (!payload.id) {
      return;
    }
    const data = yield call(evaluacionApi(token).cambios, payload);
    yield put(evaluacionActions.setEvaluacionCambios(data));
  } catch (error) {
    //
    yield put(evaluacionActions.setEvaluacionCambios({}));
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* duplicarEvaluacionSaga({ payload }) {
  const token = getToken();
  try {
    if (!payload.id) {
      return;
    }
    const data = yield call(evaluacionApi(token).detail, payload);
    //
    const {
      id,
      nombre,
      descripcion,
      fecha_inicio,
      fecha_finalizacion,
      ...copia
    } = data;
    yield put(evaluacionActions.setEvaluacion(copia));
    yield put(evaluacionActions.setDuplicarEvaluacionId(null));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* evaluacionUpdateSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const params = { ...evaluacion };
  //
  try {
    const data = yield call(evaluacionApi(token).update, params);
    //
    yield put(evaluacionActions.evaluacionCreateComplete());
    yield put(push("/evaluaciones/" + params.tipo_evaluacion));
    yield put(evaluacionActions.getEvaluaciones());
    SuccessToast({ message: "Evaluación editada con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.evaluacionCreateComplete());
    ErrorToast({ message: "Ocurrió un error." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* evaluacionCreateSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const params = { ...evaluacion };
  try {
    const data = yield call(evaluacionApi(token).create, params);
    //
    yield put(evaluacionActions.evaluacionCreateComplete());
    yield put(push("/evaluaciones/" + params.tipo_evaluacion));
    yield put(evaluacionActions.getEvaluaciones());
    SuccessToast({ message: "Evaluación creada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.evaluacionError());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* evaluacionDeleteSaga({ payload }) {
  const token = getToken();
  const tipo_evaluacion = yield select(
    (state) => state.evaluacion.tipo_evaluacion_actual
  );
  const evaluacion = payload;
  //
  try {
    const data = yield call(evaluacionApi(token).delete, evaluacion);
    yield put(push("/evaluaciones/" + tipo_evaluacion));
    yield put(evaluacionActions.getEvaluaciones());
    yield put(evaluacionActions.evaluacionDeleteSuccess());
    SuccessToast({ message: "Evaluación eliminada con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.evaluacionDeleteSuccess());
    ErrorToast({
      message:
        "Error al intentar eliminar la evaluación, probablemente ya tenga formularios asignados.",
    });
  }
}

function* evaluacionBulkDeleteSaga({ payload }) {
  const token = getToken();
  const evaluaciones_checked = yield select(
    (state) => state.evaluacion.evaluaciones_checked
  );
  if (evaluaciones_checked.length === 0) {
    ErrorToast({ message: "Debe seleccionar al menos una evaluación." });
    yield put(evaluacionActions.evaluacionDeleteSuccess());
    return;
  }
  //
  try {
    const data = yield call(
      evaluacionApi(token).bulkDelete,
      evaluaciones_checked
    );
    //
    yield put(evaluacionActions.getEvaluaciones());
    yield put(evaluacionActions.evaluacionDeleteSuccess());
    yield put(evaluacionActions.resetCheckEvaluaciones());
    SuccessToast({ message: "Evaluaciones eliminadas con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.evaluacionDeleteSuccess());
    ErrorToast({ message: "Ocurrió un error el eliminar las evaluaciones." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

// FORMULARIOS
function* formulariosSaga({ payload }) {
  const token = getToken();
  const formularios_filter = yield select(
    (state) => state.evaluacion.formularios_filter
  );
  //
  try {
    const data = yield call(evaluacionApi(token).formularios, {
      ...payload,
      ...formularios_filter,
    });
    //
    yield put(evaluacionActions.setFormularios(data.results));
    yield put(evaluacionActions.setFormulariosCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* formularioDeleteSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = payload;
  const params = { id: evaluacion.id, formularioId: formulario.id };
  //
  try {
    const data = yield call(evaluacionApi(token).eliminarFormulario, params);
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.formularioEliminarComplete());
    SuccessToast({ message: "Formulario eliminado con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.formularioEliminarComplete());
    ErrorToast({
      message: "Error al intentar eliminar el formulario.",
    });
  }
}

// CREAR/EDITAR FORMULARIOS
function* colaboradoresValidosSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const colaboradores_validos_filter = yield select(
    (state) => state.evaluacion.colaboradores_validos_filter
  );
  const params = { id: evaluacion.id, ...colaboradores_validos_filter };
  //
  try {
    const data = yield call(evaluacionApi(token).colaboradoresValidos, params);
    yield put(evaluacionActions.setColaboradoresValidos(data.results));
    yield put(evaluacionActions.setColaboradoresValidosCount(data.count));
    yield put(evaluacionActions.colaboradoresValidosComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.colaboradoresValidosComplete());
  }
}

function* agregarColaboradorSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const colaboradores_agregar = yield select(
    (state) => state.evaluacion.colaboradores_agregar
  );
  //
  const params = {
    id: evaluacion.id,
    colaboradores_ids: colaboradores_agregar,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).agregarColaborador, params);
    //
    yield put(evaluacionActions.colaboradoresAgregarComplete());
    // yield put(commonActions.setSelectModal({ open: false }));
    yield put(evaluacionActions.resetCheckColaboradoresValidos());
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.getFormularios());
    SuccessToast({ message: "Colaborador(es) añadido(s) con éxito." });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.colaboradoresAgregarComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* evaluadoresColaboradorSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const colaboradorId = payload.colaboradorId;
  const evaluadores_colaborador_filter = yield select(
    (state) => state.evaluacion.evaluadores_colaborador_filter
  );
  const params = {
    id: evaluacion.id,
    colaboradorId: colaboradorId,
    ...evaluadores_colaborador_filter,
  };
  //
  try {
    const data = yield call(
      evaluacionApi(token).evaluadoresColaborador,
      params
    );
    yield put(evaluacionActions.setEvaluadoresColaborador(data.results));
    yield put(evaluacionActions.evaluadoresColaboradorComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.evaluadoresColaboradorComplete());
  }
}

function* agregarEvaluadoresSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const evaluadores_evaluado = yield select(
    (state) => state.evaluacion.evaluadores_evaluado
  );
  const evaluadores_agregar = yield select(
    (state) => state.evaluacion.evaluadores_agregar
  );
  const { relacion } = payload;
  //
  const params = {
    id: evaluacion.id,
    formularioId: evaluadores_evaluado.formulario_id,
    evaluadores_ids: evaluadores_agregar,
    relacion: relacion,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).agregarEvaluador, params);
    //
    yield put(evaluacionActions.evaluadoresAgregarComplete());
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(evaluacionActions.resetCheckEvaluadoresColaborador());
    //
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.getFormularios());
    SuccessToast({ message: "Evaluadores agregados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.colaboradoresAgregarComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* agregarEvaluadorDefectoSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const evaluadores_evaluado = yield select(
    (state) => state.evaluacion.evaluadores_evaluado
  );
  const opciones_por_defecto = yield select(
    (state) => state.evaluacion.opciones_por_defecto
  );
  //
  const params = {
    id: evaluacion.id,
    formularioId: evaluadores_evaluado.formulario_id,
    opciones_por_defecto: opciones_por_defecto,
  };
  //
  try {
    const data = yield call(
      evaluacionApi(token).agregarEvaluadorDefecto,
      params
    );
    //
    yield put(evaluacionActions.opcionesPorDefectoComplete());
    yield put(evaluacionActions.resetOpcionesPorDefecto());
    yield put(commonActions.setSelectModal({ open: false }));
    //
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.getFormularios());
    SuccessToast({ message: "Evaluadores agregados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.opcionesPorDefectoComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* agregarCalibradorDefectoSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularioId = payload.id;
  //
  const params = {
    id: evaluacion.id,
    formularioId: formularioId,
  };
  //
  try {
    const data = yield call(
      evaluacionApi(token).agregarCalibradorDefecto,
      params
    );
    //
    yield put(evaluacionActions.agregarCalibradorDefectoComplete());
    yield put(evaluacionActions.getFormularios());
    SuccessToast({ message: "Calibrador añadido con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarCalibradorDefectoComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* agregarCalibradorSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const evaluadores_evaluado = yield select(
    (state) => state.evaluacion.evaluadores_evaluado
  );
  const colaboradores_validos_checked = yield select(
    (state) => state.evaluacion.colaboradores_validos_checked
  );
  //
  const params = {
    id: evaluacion.id,
    formularioId: evaluadores_evaluado.formulario_id,
    calibradorId: colaboradores_validos_checked[0],
  };
  //
  try {
    const data = yield call(evaluacionApi(token).agregarCalibrador, params);
    //
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(evaluacionActions.agregarCalibradorComplete());
    yield put(evaluacionActions.resetCheckColaboradoresValidos());
    //
    yield put(evaluacionActions.getFormularios());
    SuccessToast({ message: "Calibrador agregado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarCalibradorComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* agregarConfiguracionSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const evaluadores_evaluado = yield select(
    (state) => state.evaluacion.evaluadores_evaluado
  );
  const params = {
    id: evaluacion.id,
    formularioId: evaluadores_evaluado.formulario_id,
    configuracionId: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).agregarConfiguracion, params);
    //
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(evaluacionActions.agregarConfiguracionComplete());
    yield put(evaluacionActions.getFormularios());
    SuccessToast({ message: "Formulario agregado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarCalibradorComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}
// /
function* agregarEvaluadoresDefaultSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = yield select(
    (state) => state.evaluacion.formularios_checked
  );
  const opciones_por_defecto = payload;
  const params = {
    id: evaluacion.id,
    formulariosIds: formularios_checked,
    opciones_por_defecto: opciones_por_defecto,
  };
  //
  try {
    const data = yield call(
      evaluacionApi(token).agregarEvaluadoresDefecto,
      params
    );
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
    yield put(commonActions.setSelectModal({ open: false }));
    SuccessToast({ message: "Evaluadores agregados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
  }
}

function* agregarCalibradoresSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = yield select(
    (state) => state.evaluacion.formularios_checked
  );
  const params = {
    id: evaluacion.id,
    formulariosIds: formularios_checked,
    calibradorId: payload[0],
    ignorar_errores: true,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).agregarCalibradores, params);
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(evaluacionActions.agregarCalibradoresComplete());
    yield put(commonActions.setSelectModal({ open: false }));
    SuccessToast({ message: "Calibrador agregado con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarCalibradoresComplete());
  }
}

function* agregarCalibradoresDefaultSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = yield select(
    (state) => state.evaluacion.formularios_checked
  );
  const params = {
    id: evaluacion.id,
    formulariosIds: formularios_checked,
    ignorar_errores: true,
  };
  //
  try {
    const data = yield call(
      evaluacionApi(token).agregarCalibradoresDefecto,
      params
    );
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(evaluacionActions.agregarCalibradoresDefaultComplete());
    yield put(commonActions.setSelectModal({ open: false }));
    SuccessToast({ message: "Calibradores agregados con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarCalibradoresDefaultComplete());
  }
}

function* agregarConfiguracionesSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = yield select(
    (state) => state.evaluacion.formularios_checked
  );
  const params = {
    id: evaluacion.id,
    formulariosIds: formularios_checked,
    configuracionId: payload,
    ignorar_errores: true,
  };
  //
  try {
    const data = yield call(
      evaluacionApi(token).agregarConfiguraciones,
      params
    );
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(evaluacionActions.agregarConfiguracionesComplete());
    yield put(commonActions.setSelectModal({ open: false }));
    SuccessToast({ message: "Formulario agregado con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarConfiguracionesComplete());
  }
}
//
function* quitarEvaluadorSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = payload.formularios_checked || [];
  const params = {
    id: evaluacion.id,
    formulariosIds: formularios_checked,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).quitarEvaluador, params);
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(commonActions.setSelectModal({ open: false }));
    SuccessToast({ message: "Evaluadores desvinculados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
  }
}

function* quitarCalibradorSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = payload.formularios_checked || [];
  const params = {
    id: evaluacion.id,
    formulariosIds: formularios_checked,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).quitarCalibrador, params);
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(commonActions.setSelectModal({ open: false }));
    SuccessToast({ message: "Calibradores desvinculados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
  }
}

function* quitarConfiguracionSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = payload.formularios_checked || [];
  const params = {
    id: evaluacion.id,
    formulariosIds: formularios_checked,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).quitarConfiguracion, params);
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
    yield put(commonActions.setSelectModal({ open: false }));
    SuccessToast({ message: "Formularios desvinculados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.agregarEvaluadoresDefaultComplete());
  }
}
//
function* eliminarFormulariosSaga() {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = yield select(
    (state) => state.evaluacion.formularios_checked
  );
  const params = { id: evaluacion.id, formulariosIds: formularios_checked };
  //
  try {
    const data = yield call(evaluacionApi(token).eliminarFormularios, params);
    //
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.resetCheckFormularios());
    SuccessToast({ message: "Formularios eliminados con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.eliminarFormulariosComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
// Envio de formularios
function* enviarFormularioSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const params = { id: evaluacion.id, formularioId: payload };
  //
  try {
    const data = yield call(evaluacionApi(token).enviarFormulario, params);
    yield put(evaluacionActions.getFormularios());
    SuccessToast({ message: "Formulario enviado con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.enviosComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* enviarFormulariosSaga() {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = yield select(
    (state) => state.evaluacion.formularios_checked
  );
  const params = { id: evaluacion.id, formulariosIds: formularios_checked };
  //
  try {
    const data = yield call(evaluacionApi(token).enviarFormularios, params);
    //
    yield put(evaluacionActions.getFormularios());
    yield put(evaluacionActions.resetCheckFormularios());
    SuccessToast({ message: "Formularios enviado con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.enviosComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
// Configuracion
function* configuracionesSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const configuraciones_filter = yield select(
    (state) => state.evaluacion.configuraciones_filter
  );
  const params = { id: evaluacion.id, ...configuraciones_filter }; // ...configuraciones_filter
  !!process.env.REACT_APP_DEBUG && console.log("configuracionesSaga saga 1");
  try {
    const data = yield call(evaluacionApi(token).listConfiguraciones, params);
    yield put(evaluacionActions.setConfiguraciones(data.results));
    yield put(evaluacionActions.setConfiguracionesComplete());
  } catch (error) {
    yield put(evaluacionActions.setConfiguracionesComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* configuracionCreateSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const configuracion = yield select((state) => state.evaluacion.configuracion);
  const params = { evaluacionId: evaluacion.id, ...configuracion };
  //
  try {
    const data = yield call(evaluacionApi(token).createConfiguracion, params);
    //
    yield put(evaluacionActions.configuracionCreateComplete());
    yield put(evaluacionActions.getConfiguraciones());
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(push(`/evaluaciones/detail/${evaluacion.id}`));
    SuccessToast({ message: "Formulario creado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.configuracionError());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* configuracionUpdateSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const configuracion = yield select((state) => state.evaluacion.configuracion);
  const params = {
    evaluacionId: evaluacion.id,
    configuracionId: configuracion.id,
    ...configuracion,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).updateConfiguracion, params);
    //
    yield put(evaluacionActions.configuracionCreateComplete());
    yield put(evaluacionActions.getConfiguraciones());
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(push(`/evaluaciones/detail/${evaluacion.id}`));
    SuccessToast({ message: "Formulario editado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(evaluacionActions.configuracionError());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* configuracionDeleteSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const configuracion_delete = yield select(
    (state) => state.evaluacion.configuracion_delete
  );
  const params = {
    evaluacionId: evaluacion.id,
    configuracionId: configuracion_delete.id,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).deleteConfiguracion, params);
    //
    yield put(evaluacionActions.getConfiguraciones());
    yield put(evaluacionActions.configuracionDeleteSuccess());
    SuccessToast({ message: "Formulario eliminado con éxito!" });
  } catch (error) {
    yield put(evaluacionActions.configuracionDeleteSuccess());
    ErrorToast({
      message: "Error al intentar eliminar el formulario.",
    });
  }
}

//
function* formularioSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(evaluacionApi(token).formularioDetalle, payload);
    yield put(evaluacionActions.setFormulario(data));
    yield put(evaluacionActions.setFormularioComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.setFormularioComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
// procesos de evaluaciones
function* evaluarSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).evaluar, params);
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    SuccessToast({ message: "Evaluación realizada con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* calibrarSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).calibrar, params);
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    // yield put(evaluacionActions.evaluacionComplete());
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    SuccessToast({ message: "Calibración realizada con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* devolverSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).devolver, params);
    //
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    // yield put(evaluacionActions.evaluacionComplete());
    SuccessToast({ message: "Devolución realizada con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* comentarSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).comentar, params);
    //
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    // yield put(evaluacionActions.evaluacionComplete());
    SuccessToast({ message: "Comentario realizado con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

// Reinicios de formulario(s)
function* reiniciarFormularioSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const params = {
    id: evaluacion.id,
    formularioId: payload.formularioId,
    data: { etapas: payload.etapas },
  };
  //
  try {
    const data = yield call(evaluacionApi(token).reiniciarFormulario, params);
    //
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.setFormulariosFilter({ id: evaluacion.id, nombre: "" }));
    SuccessToast({ message: "Formulario reiniciado con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* reiniciarFormulariosSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formularios_checked = yield select(
    (state) => state.evaluacion.formularios_checked
  );
  const data = { etapas: payload, formularios_ids: formularios_checked };
  const params = { id: evaluacion.id, data: data };
  //
  try {
    const data = yield call(evaluacionApi(token).reiniciarFormularios, params);
    //
    yield put(evaluacionActions.resetCheckFormularios());
    yield put(evaluacionActions.getEvaluacion({ id: evaluacion.id }));
    yield put(evaluacionActions.setFormulariosFilter({ id: evaluacion.id, nombre: "" }));
    SuccessToast({ message: "Formularios reiniciados con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
//
function* evaluacionReabrirSaga({ payload }) {
  const token = getToken();
  const params = { id: payload };
  //
  try {
    const data = yield call(evaluacionApi(token).reabrir, params);
    yield put(evaluacionActions.getEvaluaciones());
    SuccessToast({ message: "Evaluación reabierta con éxito!" });
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* evaluacionFinalizarSaga({ payload }) {
  const token = getToken();
  // const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const params = { id: payload };
  //
  try {
    const data = yield call(evaluacionApi(token).finalizar, params);
    yield put(evaluacionActions.getEvaluacion({ id: payload }));
    SuccessToast({ message: "Evaluación finalizada con éxito!" });
    //
  } catch (error) {
    ErrorToast({ message: "Ocurrió un error." });
  }
}
//
function* cambiosNominaSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(evaluacionApi(token).cambiosNomina, payload);
    yield put(evaluacionActions.setCambiosNomina(data));
  } catch (error) {
    yield put(evaluacionActions.setConfiguracionesComplete());
  }
}
function* actualizarNominaSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const cambios_nomina_checked = yield select(
    (state) => state.evaluacion.cambios_nomina_checked
  );
  //
  const params = {
    id: evaluacion.id,
    colaboradores: cambios_nomina_checked,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).actualizarNomina, params);
    //
    yield put(evaluacionActions.setActualizarNominaFin());
    yield put(push(`/evaluaciones/detail/${evaluacion.id}`));
    SuccessToast({ message: "¡Nómina de evaluación actualizada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}
//
function* cambiosCompetenciasSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(evaluacionApi(token).cambiosCompetencias, payload);
    yield put(evaluacionActions.setCambiosCompetencias(data));
  } catch (error) {
    yield put(evaluacionActions.setConfiguracionesComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}
function* actualizarCompetenciasSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const cambios_competencias_checked = yield select(
    (state) => state.evaluacion.cambios_competencias_checked
  );
  //
  const params = {
    id: evaluacion.id,
    competencias: cambios_competencias_checked,
  };
  //
  try {
    const data = yield call(
      evaluacionApi(token).actualizarCompetencias,
      params
    );
    //
    yield put(evaluacionActions.setActualizarCompetenciasFin());
    yield put(push(`/evaluaciones/detail/${evaluacion.id}`));
    SuccessToast({ message: "Competencias actualizadas con éxito!" });
  } catch (error) {
    ErrorToast({ message: "Ocurrió un error." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}
// INFORMES
function* InformeEvaluacionSaga({ payload }) {
  const token = getToken();
  const evaluacionId = payload.id ?? payload;
  //
  try {
    const data = yield call(evaluacionApi(token).informeEvaluacion, {
      id: evaluacionId,
      ...payload,
    });
    //
    yield put(evaluacionActions.setInformeEvaluacion(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.informeEvaluacionError());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* InformeEvaluacionConductasSaga({ payload }) {
  const token = getToken();
  const evaluacionId = payload.id ?? payload;
  //
  try {
    const data = yield call(evaluacionApi(token).informeEvaluacionConductas, {
      id: evaluacionId,
      ...payload,
    });
    //
    yield put(evaluacionActions.setInformeEvaluacionConductas(data));
  } catch (error) {
    console.error({ error });
    yield put(evaluacionActions.informeEvaluacionError());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* InformeColaboradorSaga({ payload }) {
  const token = getToken();
  const params = payload;
  //
  try {
    if (!payload.id) {
      return;
    }
    const data = yield call(evaluacionApi(token).informeColaborador, params);
    //
    yield put(evaluacionActions.setInformeColaborador(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.informeColaboradorError());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* colaboradoresPromediosSaga({ payload }) {
  const token = getToken();
  // const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const colaboradores_promedios_filter = yield select(
    (state) => state.evaluacion.colaboradores_promedios_filter
  );
  const params = { id: payload.id, ...colaboradores_promedios_filter };
  //
  try {
    const data = yield call(
      evaluacionApi(token).colaboradoresPromedios,
      params
    );
    //
    yield put(evaluacionActions.setColaboradoresPromedios(data.results));
    yield put(evaluacionActions.setColaboradoresPromediosCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(evaluacionActions.colaboradoresPromediosError());
  }
}
/////
function* areasPromediosSaga({ payload }) {
  const token = getToken();
  const areas_promedios_filter = yield select(
    (state) => state.evaluacion.areas_promedios_filter
  );
  const params = { id: payload.id, ...areas_promedios_filter };
  if (params.nombre?.length > 0 && params.nombre?.length < 4) {
    yield put(evaluacionActions.areasPromediosError());
    return;
  }
  try {
    const data = yield call(evaluacionApi(token).areasPromedios, params);
    yield put(evaluacionActions.setAreasPromedios(data.results));
  } catch (error) {
    yield put(evaluacionActions.areasPromediosError());
  }
}

function* sucursalesPromediosSaga({ payload }) {
  const token = getToken();
  const sucursales_promedios_filter = yield select(
    (state) => state.evaluacion.sucursales_promedios_filter
  );
  const params = { id: payload.id, ...sucursales_promedios_filter };
  if (params.nombre?.length > 0 && params.nombre?.length < 4) {
    yield put(evaluacionActions.sucursalesPromediosError());
    return;
  }
  try {
    const data = yield call(evaluacionApi(token).sucursalesPromedios, params);
    yield put(evaluacionActions.setSucursalesPromedios(data.results));
  } catch (error) {
    yield put(evaluacionActions.sucursalesPromediosError());
  }
}

function* departamentosPromediosSaga({ payload }) {
  const token = getToken();
  const departamentos_promedios_filter = yield select(
    (state) => state.evaluacion.departamentos_promedios_filter
  );
  const params = { id: payload.id, ...departamentos_promedios_filter };
  if (params.nombre?.length > 0 && params.nombre?.length < 4) {
    yield put(evaluacionActions.departamentosPromediosError());
    return;
  }
  try {
    const data = yield call(
      evaluacionApi(token).departamentosPromedios,
      params
    );
    yield put(evaluacionActions.setDepartamentosPromedios(data.results));
  } catch (error) {
    yield put(evaluacionActions.departamentosPromediosError());
  }
}

function* areaColaboradoresSaga({ payload }) {
  const token = getToken();
  // const departamentos_promedios_filter = yield select(
  //   (state) => state.evaluacion.departamentos_promedios_filter
  // );
  const params = { id: payload.id, ...payload };
  //
  try {
    const data = yield call(evaluacionApi(token).getAreaColaboradores, params);
    yield put(evaluacionActions.setAreaColaboradores(data.results));
    yield put(evaluacionActions.getAreaColaboradoresComplete());
  } catch (error) {
    //
    yield put(evaluacionActions.getAreaColaboradoresComplete());
  }
}
// Saga para obtener colaboradores de una sucursal
function* sucursalColaboradoresSaga({ payload }) {
  const token = getToken();
  const params = { id: payload.id, ...payload };
  //
  try {
    const data = yield call(
      evaluacionApi(token).getSucursalColaboradores,
      params
    );
    yield put(evaluacionActions.setSucursalColaboradores(data.results));
    yield put(evaluacionActions.getSucursalColaboradoresComplete());
  } catch (error) {
    //
    yield put(evaluacionActions.getSucursalColaboradoresComplete());
  }
}
// Saga para obtener colaboradores de un departamento
function* departamentoColaboradoresSaga({ payload }) {
  const token = getToken();
  const params = { id: payload.id, ...payload };
  //
  try {
    const data = yield call(
      evaluacionApi(token).getDepartamentoColaboradores,
      params
    );
    yield put(evaluacionActions.setDepartamentoColaboradores(data.results));
    yield put(evaluacionActions.getDepartamentoColaboradoresComplete());
  } catch (error) {
    //
    yield put(evaluacionActions.getDepartamentoColaboradoresComplete());
  }
}

function* getNineboxDataSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(evaluacionApi(token).getNineboxData, payload);
    yield put(evaluacionActions.setNineboxData(data));
  } catch (error) {
    console.log(error);
    yield put(evaluacionActions.setNineboxData([]));
    ErrorToast({ message: "Error al obtener datos para el Ninebox." });
  }
}

function* getNineboxDataIndividualSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      evaluacionApi(token).getNineboxDataIndividual,
      payload
    );
    yield put(evaluacionActions.setNineboxDataIndividual(data));
  } catch (error) {
    console.log(error);
    yield put(evaluacionActions.setNineboxDataIndividual([]));
    ErrorToast({
      message: "Error al obtener datos para el Ninebox del colaborador.",
    });
  }
}

function* getColaboradoresNineboxSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      evaluacionApi(token).getColaboradoresNinebox,
      payload
    );
    yield put(evaluacionActions.setColaboradoresNinebox(data));
  } catch (error) {
    console.log(error);
    yield put(evaluacionActions.setColaboradoresNinebox([]));
    ErrorToast({ message: "Error al obtener colaboradores para el Ninebox." });
  }
}

//
export function* evaluacionSagas() {
  // Evaluacion
  yield takeLatest(evaluacionActions.getEvaluaciones.type, evaluacionesSaga);
  yield takeLatest(
    evaluacionActions.setEvaluacionesFilter.type,
    evaluacionesSaga
  );
  yield takeEvery(evaluacionActions.getEvaluacion.type, evaluacionDetailSaga);
  yield takeEvery(
    evaluacionActions.getEvaluacionCambios.type,
    evaluacionCambiosSaga
  );
  yield takeEvery(
    evaluacionActions.duplicarEvaluacion.type,
    duplicarEvaluacionSaga
  );
  yield takeEvery(
    evaluacionActions.createEvaluacion.type,
    evaluacionCreateSaga
  );
  yield takeEvery(
    evaluacionActions.updateEvaluacion.type,
    evaluacionUpdateSaga
  );
  yield takeEvery(
    evaluacionActions.evaluacionDelete.type,
    evaluacionDeleteSaga
  );
  yield takeEvery(
    evaluacionActions.evaluacionBulkDelete.type,
    evaluacionBulkDeleteSaga
  );
  //
  yield takeEvery(
    evaluacionActions.setEvaluacionReabrir.type,
    evaluacionReabrirSaga
  );
  yield takeEvery(
    evaluacionActions.setEvaluacionFinalizar.type,
    evaluacionFinalizarSaga
  );
  // Formulario
  yield takeLatest(
    evaluacionActions.getColaboradoresValidos.type,
    colaboradoresValidosSaga
  );
  yield takeLatest(
    evaluacionActions.setColaboradoresValidosFilter.type,
    colaboradoresValidosSaga
  );
  //
  yield takeLatest(evaluacionActions.getFormularios.type, formulariosSaga);
  yield takeLatest(
    evaluacionActions.setFormulariosFilter.type,
    formulariosSaga
  );
  yield takeEvery(
    evaluacionActions.setFormularioEliminar.type,
    formularioDeleteSaga
  );
  //
  yield takeEvery(
    evaluacionActions.setColaboradoresAgregar.type,
    agregarColaboradorSaga
  );
  //
  yield takeLatest(
    evaluacionActions.getEvaluadoresColaborador.type,
    evaluadoresColaboradorSaga
  );
  yield takeLatest(
    evaluacionActions.setEvaluadoresColaboradorFilter.type,
    evaluadoresColaboradorSaga
  );
  yield takeEvery(
    evaluacionActions.setEvaluadoresAgregar.type,
    agregarEvaluadoresSaga
  );
  //
  yield takeEvery(
    evaluacionActions.setOpcionesPorDefecto.type,
    agregarEvaluadorDefectoSaga
  );
  yield takeEvery(
    evaluacionActions.agregarCalibradorDefecto.type,
    agregarCalibradorDefectoSaga
  );
  yield takeEvery(
    evaluacionActions.agregarCalibrador.type,
    agregarCalibradorSaga
  );
  yield takeEvery(
    evaluacionActions.agregarConfiguracion.type,
    agregarConfiguracionSaga
  );
  // /
  yield takeEvery(
    evaluacionActions.agregarEvaluadoresDefault.type,
    agregarEvaluadoresDefaultSaga
  );
  yield takeEvery(
    evaluacionActions.agregarCalibradores.type,
    agregarCalibradoresSaga
  );
  yield takeEvery(
    evaluacionActions.agregarCalibradoresDefault.type,
    agregarCalibradoresDefaultSaga
  );
  yield takeEvery(
    evaluacionActions.agregarConfiguraciones.type,
    agregarConfiguracionesSaga
  );
  yield takeEvery(
    evaluacionActions.setEliminarFormularios.type,
    eliminarFormulariosSaga
  );
  //
  yield takeEvery(
    evaluacionActions.setQuitarEvaluador.type,
    quitarEvaluadorSaga
  );
  yield takeEvery(
    evaluacionActions.setQuitarCalibrador.type,
    quitarCalibradorSaga
  );
  yield takeEvery(
    evaluacionActions.setQuitarConfiguracion.type,
    quitarConfiguracionSaga
  );
  // envio formularios
  yield takeEvery(
    evaluacionActions.setEnviarFormulario.type,
    enviarFormularioSaga
  );
  yield takeEvery(
    evaluacionActions.setEnviarFormularios.type,
    enviarFormulariosSaga
  );
  // Configuracion
  yield takeLatest(
    evaluacionActions.getConfiguraciones.type,
    configuracionesSaga
  );
  yield takeLatest(
    evaluacionActions.setConfiguracionesFilter.type,
    configuracionesSaga
  );
  yield takeEvery(
    evaluacionActions.createConfiguracion.type,
    configuracionCreateSaga
  );
  yield takeEvery(
    evaluacionActions.updateConfiguracion.type,
    configuracionUpdateSaga
  );
  yield takeEvery(
    evaluacionActions.configuracionDelete.type,
    configuracionDeleteSaga
  );
  //
  yield takeEvery(evaluacionActions.getFormulario.type, formularioSaga);
  //
  yield takeEvery(evaluacionActions.evaluar.type, evaluarSaga);
  yield takeEvery(evaluacionActions.calibrar.type, calibrarSaga);
  yield takeEvery(evaluacionActions.devolver.type, devolverSaga);
  yield takeEvery(evaluacionActions.comentar.type, comentarSaga);
  //
  yield takeEvery(
    evaluacionActions.reiniciarFormulario.type,
    reiniciarFormularioSaga
  );
  yield takeEvery(
    evaluacionActions.reiniciarFormularios.type,
    reiniciarFormulariosSaga
  );
  yield takeEvery(evaluacionActions.getCambiosNomina.type, cambiosNominaSaga);
  yield takeEvery(
    evaluacionActions.setActualizarNomina.type,
    actualizarNominaSaga
  );
  //
  yield takeEvery(
    evaluacionActions.getCambiosCompetencias.type,
    cambiosCompetenciasSaga
  );
  yield takeEvery(
    evaluacionActions.setActualizarCompetencias.type,
    actualizarCompetenciasSaga
  );
  // Informes;
  yield takeLatest(
    evaluacionActions.getInformeEvaluacion.type,
    InformeEvaluacionSaga
  );
  yield takeLatest(
    evaluacionActions.filterInformeEvaluacion.type,
    InformeEvaluacionSaga
  );
  //
  yield takeLatest(
    evaluacionActions.getInformeEvaluacionConductas.type,
    InformeEvaluacionConductasSaga
  );
  yield takeLatest(
    evaluacionActions.filterInformeEvaluacionConductas.type,
    InformeEvaluacionConductasSaga
  );
  //
  yield takeEvery(
    evaluacionActions.getInformeColaborador.type,
    InformeColaboradorSaga
  );
  //
  yield takeLatest(
    evaluacionActions.getColaboradoresPromedios.type,
    colaboradoresPromediosSaga
  );
  yield takeLatest(
    evaluacionActions.setColaboradoresPromediosFilter.type,
    colaboradoresPromediosSaga
  );
  //
  yield takeLatest(
    evaluacionActions.getAreasPromedios.type,
    areasPromediosSaga
  );
  yield takeLatest(
    evaluacionActions.setAreasPromediosFilter.type,
    areasPromediosSaga
  );

  yield takeLatest(
    evaluacionActions.getSucursalesPromedios.type,
    sucursalesPromediosSaga
  );
  yield takeLatest(
    evaluacionActions.setSucursalesPromediosFilter.type,
    sucursalesPromediosSaga
  );

  yield takeLatest(
    evaluacionActions.getDepartamentosPromedios.type,
    departamentosPromediosSaga
  );
  yield takeLatest(
    evaluacionActions.setDepartamentosPromediosFilter.type,
    departamentosPromediosSaga
  );
  //
  yield takeLatest(
    evaluacionActions.getAreaColaboradores.type,
    areaColaboradoresSaga
  );
  yield takeLatest(
    evaluacionActions.getSucursalColaboradores.type,
    sucursalColaboradoresSaga
  );

  yield takeLatest(
    evaluacionActions.getDepartamentoColaboradores.type,
    departamentoColaboradoresSaga
  );
  //
  yield takeEvery(evaluacionActions.getNineboxData.type, getNineboxDataSaga);
  yield takeEvery(
    evaluacionActions.getNineboxDataIndividual.type,
    getNineboxDataIndividualSaga
  );
  yield takeEvery(
    evaluacionActions.getColaboradoresNinebox.type,
    getColaboradoresNineboxSaga
  );
  yield takeLatest(
    evaluacionActions.setColaboradoresNineboxFilter.type,
    getColaboradoresNineboxSaga
  );
}
