import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import backButton from "src/modules/common/components/images/back-arrow.png";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import { ProfileImageMedium } from "src/modules/common/components/ProfileImageMedium";
import { commonActions } from "src/modules/common/handler/redux";
import { EvaluacionTips } from "../components/EvaluacionTips";
import { evaluacionActions } from "../handlers/redux";
import { FormularioRoutes } from "../handlers/routes";
import { puntuacionesDefault } from "../helpers/constants";
import axios from "axios";
import { getToken } from "src/utils/helpers";
import { FileEarmarkArrowDownFill } from "react-bootstrap-icons";

function FormularioPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();
  const { idEvaluacion, idFormulario } = params;
  const steps = [
    "evaluacion",
    "calibracion",
    "devolucion",
    "comentario",
    "finalizado",
  ];
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const formulario = useSelector((state) => state.evaluacion.formulario);
  const formulario_loading = useSelector(
    (state) => state.evaluacion.formulario_loading
  );
  const { clasificaciones = [] } = evaluacion;
  const {
    evaluado,
    evaluador,
    calibrador,
    relacion,
    configuracion,
    puntaje_obtenido,
    puntaje_evaluacion,
    puntaje_calibracion,
    puntaje_devolucion,
  } = formulario;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const getClasificacion = (puntaje) => {
    let clasificacion = "Sin definir";
    const clasificacionObj = clasificaciones.find(
      (clasif, cIdx) =>
        puntaje >= clasif.puntaje_desde && puntaje <= clasif.puntaje_hasta
    );
    //
    if (!!clasificacionObj && Object.keys(clasificacionObj).length > 0) {
      clasificacion = clasificacionObj.nombre;
    }
    return clasificacion;
  };
  const getAvailableSteps = () => {
    const {
      tiene_calibracion_superior,
      tiene_devolucion,
      tiene_comentarios_evaluado,
    } = evaluacion;
    const { calibrador } = formulario;
    let stepsCopy = [];
    //
    if (formulario.relacion !== "Jefe directo") {
      stepsCopy.push("evaluacion");
      stepsCopy.push("finalizado");
    } else {
      stepsCopy.push("evaluacion");
      // si es Evaluador o Calibrador puede ver todos los pasos disponibles
      if (tiene_calibracion_superior && !!calibrador) {
        stepsCopy.push("calibracion");
      }
      if (tiene_devolucion) {
        stepsCopy.push("devolucion");
      }
      if (tiene_comentarios_evaluado) {
        stepsCopy.push("comentario");
      }
      stepsCopy.push("finalizado");
    }
    //
    return stepsCopy;
  };
  const currentStep = pathname.split("/")[5] || "";
  const currenStepIndex = getAvailableSteps().indexOf(currentStep);

  const generateLinks = () => {
    let links = [];
    let stepsCopy = getAvailableSteps();
    //
    const baseURL = `/evaluaciones/${idEvaluacion}/formulario/${idFormulario}/`;
    links = stepsCopy.map((s, i) => ({
      to: baseURL + s,
      name: s,
      label: s.replace("cion", "ción"),
    }));
    return links;
  };
  const getPasosHabilitados = () => {
    const estado = formulario?.estado?.value;
    const pasosDisponibles = getAvailableSteps();
    //
    let pasosHabilitados = pasosDisponibles.filter((s, si) => si < estado);
    if (pasosHabilitados.length <= 0) {
      // si está Pendiente y no Enviado igual se habilita
      pasosHabilitados = ["evaluacion"];
    }
    //
    return pasosHabilitados;
  };
  const navLinkClickHandler = (stepIndex) => (e) => {
    const selectedStep = getAvailableSteps()[stepIndex];
    const pasosHabilitados = getPasosHabilitados();
    if (!pasosHabilitados.includes(selectedStep)) {
      e.preventDefault();
    }
  };
  const ColaboradorPicture = ({ image }) => {
    if (!image) {
      return <ProfileImageMedium />;
    }
    //
    return (
      <div className="w-full bg-slate-200 rounded-md">
        <img
          className="object-cover h-full w-full rounded-md"
          src={image}
          alt={`Foto colaborador`}
        />
      </div>
    );
  };
  const getRelacionStyle = (relacion) => {
    const styles = {
      "Jefe directo": "bg-red-500 text-white",
      Autoevaluación: "bg-slate-400 text-black",
      Par: "bg-yellow-400 text-white",
      Subalterno: "bg-sky-500 text-white",
    };
    const relacionStyle = styles[relacion] || "bg-slate-400 p-1 rounded-md";
    return relacionStyle;
  };
  const downloadPDF = () => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
    setDownloadLoading(true);
    axios({
      url:
        URL_BASE +
        `/evaluacion/${evaluacion.id}/formulario/${formulario.id}/exportar/${currentStep}`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `formulario-${currentStep}-${evaluacion.id}-${formulario.id}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
  };
  //
  useEffect(() => {
    if (Object.keys(evaluacion).length < 1) {
      dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    }
    //
    dispatch(
      evaluacionActions.getFormulario({
        id: idEvaluacion,
        formularioId: idFormulario,
      })
    );
    // /
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
  }, [idFormulario]);
  //
  return (
    <div className="">
      {/* HEADER */}
      <div className="flex items-center bg-gray-100 py-2.5 px-7 -mx-8 border-b">
        <div
          onClick={() => navigate("/evaluaciones/detail/" + idEvaluacion)}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-xl">Formulario de evaluación</div>
        <div className="flex items-center button-group"></div>
      </div>
      {/* BODY */}
      <div className="">
        {/* Datos evaluador/evaluado */}
        {!formulario_loading && (
          <div className="mx-0 md:mx-16 flex flex-col md:flex-row justify-between gap-8 mt-8">
            {/* Evaluador */}
            <div className="person-container flex-1 flex flex-col gap-2">
              <div className="font-bold text-lg border-b pb-2">Evaluador</div>
              <div className="flex gap-2">
                <div className="h-36 w-36">
                  <ColaboradorPicture
                    image={evaluador?.foto_empleado}
                  ></ColaboradorPicture>
                </div>
                <div>
                  <div className="font-bold">
                    {evaluador?.nombre
                      ? `${evaluador?.nombre} ${evaluador?.apellido}`
                      : "Sin evaluador"}
                  </div>
                  <div>Cargo: {evaluador?.cargo?.nombre || "Sin asignar"} </div>
                  <span
                    className={`${getRelacionStyle(
                      relacion
                    )} px-2 py-1 text-center rounded-md`}
                  >
                    {relacion || "Sin especificar"}
                  </span>
                </div>
              </div>
              {/* ============== */}
              <div className="flex">
                {!downloadLoading && (
                  <button
                    onClick={downloadPDF}
                    className="bg-sky-500 rounded-md text-white p-2 flex items-center gap-2"
                  >
                    <FileEarmarkArrowDownFill size={18} />
                    <span className="font-semibold">Exportar PDF</span>
                  </button>
                )}
                {!!downloadLoading && (
                  <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse">
                    Generando informe...
                  </button>
                )}
              </div>
              {/* ============== */}
            </div>
            {/* Colaborador evaluado y puntaje */}
            <div className="flex flex-1 gap-4">
              {/* Colaborador */}
              <div className="person-container w-3/4 flex flex-col gap-2">
                <div className="font-bold text-lg border-b pb-2">
                  Colaborador
                </div>
                <div className="flex gap-2">
                  <div className="h-36 w-36">
                    <ColaboradorPicture
                      image={evaluado?.foto_empleado}
                    ></ColaboradorPicture>
                  </div>
                  <div>
                    <div className="font-bold">
                      {evaluado?.nombre
                        ? `${evaluado?.nombre} ${evaluado?.apellido}`
                        : "-"}
                    </div>
                    <div>Cargo: {evaluado?.cargo?.nombre || "Sin cargo"} </div>
                    <div>
                      Formulario: {configuracion?.nombre || "Sin nombre"}{" "}
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-sky-500 w-2 h-2 rounded-full"></div>
                      Puntaje evaluador: {`${puntaje_evaluacion || 0}`}%
                    </div>
                    {!!calibrador && (
                      <div className="flex items-center gap-1">
                        <div className="bg-yellow-500 w-2 h-2 rounded-full"></div>
                        Puntaje calibrador: {`${puntaje_calibracion || 0}`}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Puntaje */}
              <div className="calif-container flex-grow">
                <div className="font-bold text-lg border-b pb-2">Puntaje</div>
                <div className="flex flex-col p-4">
                  <PercentageCircular
                    strokeWidth={4}
                    strokeColor={"blue"}
                    value={puntaje_obtenido}
                    content={
                      <>
                        <div className="font-bold text-2xl">
                          {puntaje_obtenido || 0}%
                        </div>
                        <div className="text-sm">
                          {getClasificacion(puntaje_obtenido)}
                        </div>
                      </>
                    }
                  ></PercentageCircular>
                </div>
              </div>
            </div>
          </div>
        )}
        {!!formulario_loading && (
          <div className="grid grid-cols-1 md:grid-cols-12 gap-2 animate-pulse mt-8">
            <div className="col-span-1 md:col-span-6 rounded-md bg-slate-200 w-full h-56"></div>
            <div className="col-span-1 md:col-span-4 rounded-md bg-slate-200 w-full h-56"></div>
            <div className="col-span-1 md:col-span-2 rounded-md bg-slate-200 w-full h-56"></div>
          </div>
        )}
        {/* Links item */}
        {!!formulario_loading && (
          <div className="flex w-full justify-center animate-pulse my-8">
            <div className="h-16 w-full md:w-2/5 bg-slate-200 rounded-md"></div>
          </div>
        )}
        {!formulario_loading && (
          <div className="flex justify-center w-full my-8">
            <div className="flex justify-center w-full md:w-2/5">
              {generateLinks().map((link, idx) => (
                <div
                  key={`div-f-link-${idx}`}
                  className={`borderx flex flex-col ${
                    idx + 1 !== generateLinks().length ? " flex-1" : ""
                  } gap-2`}
                >
                  <NavLink
                    key={`f-link-${idx}`}
                    onClick={navLinkClickHandler(idx)}
                    to={link.to}
                    className={({ isActive }) =>
                      `${
                        !getPasosHabilitados().includes(link.name)
                          ? "text-slate-300 hover:cursor-not-allowed hover:text-slate-300"
                          : ""
                      } flex flex-col gap-2 ${!!isActive ? "font-bold" : ""}`
                    }
                  >
                    <div className="flex items-center">
                      <div
                        className={`h-5 w-5 ${
                          currentStep === link.name
                            ? "bg-sky-500"
                            : idx < currenStepIndex
                            ? "bg-sky-500"
                            : "bg-slate-300"
                        } rounded-full`}
                      ></div>
                      {idx + 1 !== generateLinks().length && (
                        <div
                          className={`${
                            currentStep === link.name
                              ? "bg-sky-500"
                              : idx < currenStepIndex
                              ? "bg-sky-500"
                              : "bg-slate-300"
                          } h-0.5 flex-1`}
                        ></div>
                      )}
                    </div>
                    <div>
                      <span className="capitalize text-sm">{link.label}</span>
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        )}
        {/*  */}
        <EvaluacionTips
          extraClass="my-4"
          frecuencias={
            evaluacion.puntuaciones_observacion?.length > 0
              ? evaluacion.puntuaciones_observacion
              : puntuacionesDefault
          }
          antiguedad={evaluacion.antiguedad_minima}
        ></EvaluacionTips>
      </div>
      {/*  */}
      <FormularioRoutes></FormularioRoutes>
    </div>
  );
}

export default FormularioPage;
