import { createSlice } from "@reduxjs/toolkit";

const kpiSlice = createSlice({
  name: "Kpi",
  initialState: {
    kpi_create_loading: false,
    kpi_update_loading: false,
    kpi_delete_loading: false,
    colaboradorKpi_delete_loading: false,
    kpi: {},
    kpi_loading: false,
    kpis: { result: [] },
    kpis_delete: [],
    kpis_checked: [],
    kpis_filter: {},
    kpis_loading: false,
    kpi_create_modal: { open: false },
    colaborador: {},
    colaborador_loading: false,
    colaboradores: { result: [] },
    colaboradores_filter: {},
    colaboradores_loading: false,
    colaboradores_count: 0,
    colaboradores_checked: [],
    colaboradores_ids: [],
    colaboradores_ids_loading: false,
    //
    kpi_colaborador: {},
    kpi_colaborador_loading: false,
    kpi_historial: [],
    kpi_historial_loading: false,
    //
    kpiColaboradores: [],
    kpiColaboradores_filter: {},
    kpiColaboradores_loading: false,
    kpiColaboradores_delete: [],
    kpiColaboradores_checked: [],
    colaborador_add_modal: { open: false, reset: false },
    kpiColaborador_delete_loading: false,
    //
    asignar_kpis_masivo_loading: false,
    eliminar_kpis_masivo_loading: false,
    //
    colaboradorKpis: { results: [] },
    colaboradorKpis_filter: {},
    colaboradorKpis_loading: false,
    colaboradorKpis_delete: [],
    colaboradorKpis_checked: [],
    //
    colaboradorKpisNoVigentes: [],
    colaboradorKpisNoVigentes_checked: [],
    colaboradorKpisNoVigentes_count: 0,
    colaboradorKpisNoVigentes_filter: {},
    colaboradorKpisNoVigentes_loading: false,
    //
    kpis_ids_asignar: [],
    colaboradores_ids_asignar: [],
    //
    metricas: [],
    periodos: [],
    metricas_loading: false,
    periodos_loading: false,
    //
    fileToUploadPorColaborador: null,
    fileToUploadPorCargo: null,
    importar_loading: false,
    errores_importacion: [],
  },
  reducers: {
    resetCheckKpiNoVigente: (state) => {
      state.colaboradorKpisNoVigentes_checked = [];
    },
    setKpiNoVigentesChecked: (state, { payload }) => {
      state.colaboradorKpisNoVigentes_checked = payload;
    },
    checkKpiNoVigente: (state, { payload: { kpi, checked = true } }) => {
      state.colaboradorKpisNoVigentes_checked =
        state.colaboradorKpisNoVigentes_checked.filter(
          (kpinv) => kpinv.id !== kpi.id
        );
      if (!!checked) {
        state.colaboradorKpisNoVigentes_checked.push(kpi);
      }
    },
    getKpiHistorial: (state, { payload }) => {
      state.kpi_historial_loading = true;
    },
    setKpiHistorial: (state, { payload }) => {
      state.kpi_historial = payload;
      state.kpi_historial_loading = false;
    },
    asignarKpisMasivo: (state, { payload }) => {
      state.asignar_kpis_masivo_loading = true;
    },
    asignarKpisMasivoComplete: (state, { payload }) => {
      state.asignar_kpis_masivo_loading = false;
    },
    eliminarKpisMasivo: (state, { payload = {} }) => {
      state.eliminar_kpis_masivo_loading = true;
    },
    eliminarKpisMasivoComplete: (state, { payload = {} }) => {
      state.eliminar_kpis_masivo_loading = false;
    },
    //
    kpiSet: (state, { payload = {} }) => {
      state.kpi = payload;
      state.kpi_loading = false;
    },
    setKpis: (state, { payload }) => {
      state.kpis = payload.kpis;
      state.kpis_loading = false;
    },
    setKpisFilter: (state, { payload = {} }) => {
      state.kpis_filter = payload;
    },
    setColaboradores: (state, { payload }) => {
      state.colaboradores = payload.colaboradores;
      state.colaboradores_loading = false;
    },
    setColaboradoresCount: (state, { payload = {} }) => {
      state.colaboradores_count = payload;
    },
    setColaboradoresFilter: (state, { payload = {} }) => {
      state.colaboradores_loading = true;
      state.colaboradores_filter = payload;
    },
    setKpiColaboradores: (state, { payload }) => {
      state.kpiColaboradores = payload.colaboradores;
      state.kpiColaboradores_loading = false;
    },
    setKpiColaboradoresDelete: (state, { payload }) => {
      state.kpiColaboradores_delete = payload;
    },
    setColaboradorKpisDelete: (state, { payload }) => {
      state.colaboradorKpis_delete = payload;
    },
    resetKpiColaboradoresDelete: (state, { payload }) => {
      state.kpiColaboradores_delete = [];
    },
    resetColaboradorKpisDelete: (state, { payload }) => {
      state.colaboradorKpis_delete = [];
    },
    setKpiColaboradoresFilter: (state, { payload }) => {
      state.kpiColaboradores_filter = payload;
    },
    setColaboradorAddModal: (state, { payload = {} }) => {
      state.colaborador_add_modal = payload;
    },
    setColaboradorKpis: (state, { payload }) => {
      state.colaboradorKpis = payload.kpis;
      state.colaboradorKpis_loading = false;
    },
    setColaboradorKpisFilter: (state, { payload }) => {
      state.colaboradorKpis_filter = payload;
    },
    // NO VIGENTES
    getColaboradorKpisNoVigentes: (state, { payload }) => {
      state.colaboradorKpisNoVigentes_loading = true;
    },
    setColaboradorKpisNoVigentesFilter: (state, { payload }) => {
      state.colaboradorKpisNoVigentes_filter = payload;
    },
    setColaboradorKpisNoVigentes: (state, { payload }) => {
      state.colaboradorKpisNoVigentes = payload.results;
      state.colaboradorKpisNoVigentes_count = payload.count;
      state.colaboradorKpisNoVigentes_loading = false;
    },
    setColaboradorKpisNoVigentesSimple: (state, { payload }) => {
      const { results, count } = payload;
      state.colaboradorKpisNoVigentes = results;
      state.colaboradorKpisNoVigentes_count = count;
    },
    //
    setColaboradoresIds: (state, { payload }) => {
      state.colaboradores_ids = payload.colaboradoresIds;
      state.colaboradores_ids_loading = false;
    },
    setAsignarKpisColaboradores: (state, { payload = {} }) => {
      state.kpis_ids_asignar = payload.kpis_ids;
      state.colaboradores_ids_asignar = payload.colaboradores_ids;
    },
    setKpisDelete: (state, { payload = {} }) => {
      state.kpis_delete = payload;
    },
    setMetricas: (state, { payload }) => {
      state.metricas = payload.metricas;
      state.metricas_loading = false;
    },
    setPeriodos: (state, { payload }) => {
      state.periodos = payload.periodos;
      state.periodos_loading = false;
    },
    resetCheckKpis: (state) => {
      state.kpis_checked = [];
      state.kpis_loading = false;
    },
    checkKpi: (state, { payload: { kpi, checked = true } }) => {
      state.kpis_checked = state.kpis_checked.filter((id) => id != kpi.id);
      if (!!checked) {
        state.kpis_checked.push(kpi.id);
      }
      state.kpis_loading = false;
    },
    setCheckColaboradores: (state, { payload = [] }) => {
      state.colaboradores_checked = payload;
    },
    resetCheckColaboradores: (state) => {
      state.colaboradores_checked = [];
      state.colaboradores_loading = false;
    },
    checkColaborador: (state, { payload: { colaborador, checked = true } }) => {
      state.colaboradores_checked = state.colaboradores_checked.filter(
        (colab) => colab.id !== colaborador.id
      );
      if (!!checked) {
        state.colaboradores_checked.push(colaborador);
      }
    },
    //
    resetCheckKpiColaboradores: (state) => {
      state.kpiColaboradores_checked = [];
      state.kpiColaboradores_loading = false;
    },
    checkKpiColaborador: (
      state,
      { payload: { colaborador, checked = true } }
    ) => {
      state.kpiColaboradores_checked = state.kpiColaboradores_checked.filter(
        (id) => id != colaborador.id
      );
      if (!!checked) {
        state.kpiColaboradores_checked.push(colaborador.id);
      }
      state.kpiColaboradores_loading = false;
    },
    //
    resetCheckColaboradorKpis: (state) => {
      state.colaboradorKpis_checked = [];
      state.colaboradorKpis_loading = false;
    },
    checkColaboradorKpis: (state, { payload: { kpi, checked = true } }) => {
      state.colaboradorKpis_checked = state.colaboradorKpis_checked.filter(
        (id) => id != kpi.id
      );
      if (!!checked) {
        state.colaboradorKpis_checked.push(kpi.id);
      }
      state.colaboradorKpis_loading = false;
    },
    // create
    setKpiCreateModal: (state, { payload = {} }) => {
      state.kpi_create_modal = payload;
    },
    kpiCreate: (state, { payload = {} }) => {
      state.kpi_create_loading = true;
    },
    kpiCreateSuccess: (state) => {
      state.kpi_create_loading = false;
    },
    // update
    kpiUpdate: (state, { payload = {} }) => {
      state.kpi_update_loading = true;
    },
    kpiUpdateSuccess: (state) => {
      state.kpi_update_loading = false;
    },
    // delete
    kpiDelete: (state, { payload = {} }) => {
      state.kpi_delete_loading = true;
    },
    kpiDeleteSuccess: (state) => {
      state.kpi_delete_loading = false;
    },
    colaboradorKpiDelete: (state, { payload = {} }) => {
      state.colaboradorKpi_delete_loading = true;
    },
    colaboradorKpiDeleteSuccess: (state) => {
      state.colaboradorKpi_delete_loading = false;
    },
    kpiColaboradorDelete: (state, { payload = {} }) => {
      state.kpiColaborador_delete_loading = true;
    },
    kpiColaboradorDeleteSuccess: (state) => {
      state.kpiColaborador_delete_loading = false;
    },
    // get
    kpiGet: (state, { payload = {} }) => {
      state.kpi = {};
      state.kpi_loading = true;
    },
    colaboradorGet: (state, { payload = {} }) => {
      state.colaborador = {};
      state.colaborador_loading = true;
    },
    // list
    kpis: (state, { payload }) => {
      state.kpis_loading = true;
    },
    colaboradores: (state, { payload }) => {
      state.colaboradores_loading = true;
    },
    kpiColaboradores: (state, { payload }) => {
      state.kpiColaboradores_loading = true;
    },
    colaboradorKpis: (state, { payload }) => {
      state.colaboradorKpis_loading = true;
    },
    colaboradoresIds: (state, { payload }) => {
      state.colaboradores_ids_loading = true;
    },
    // KPI COLABORADOR
    getKpiColaborador: (state, { payload = {} }) => {
      state.kpi_colaborador = {};
      state.kpi_colaborador_loading = true;
    },
    setKpiColaborador: (state, { payload = {} }) => {
      state.kpi_colaborador = payload;
      state.kpi_colaborador_loading = false;
    },
    kpiColaboradorUpdate: (state, { payload = {} }) => {
      state.kpi_colaborador_loading = true;
    },
    kpiColaboradorUpdateComplete: (state, { payload = {} }) => {
      state.kpi_colaborador_loading = false;
    },
    //
    colaboradorUpdateKpis: (state, { payload = {} }) => {
      state.colaboradorKpis_loading = true;
    },
    colaboradorUpdateKpisComplete: (state, { payload }) => {
      state.colaboradorKpis_loading = false;
    },
    //
    metricas: (state, { payload }) => {
      state.metricas_loading = true;
    },
    periodos: (state, { payload }) => {
      state.periodos_loading = true;
    },
    //
    setFileToUploadPorColaborador: (state, { payload = {} }) => {
      state.fileToUploadPorColaborador = payload;
      state.importar_loading = true;
    },
    setFileToUploadPorColaboradorFinal: (state, { payload = {} }) => {
      state.fileToUploadPorColaborador = null;
      state.importar_loading = false;
    },
    setFileToUploadPorCargo: (state, { payload = {} }) => {
      state.fileToUploadPorCargo = payload;
      state.importar_loading = true;
    },
    setFileToUploadPorCargoFinal: (state, { payload = {} }) => {
      state.fileToUploadPorCargo = null;
      state.importar_loading = false;
    },
    setErroresImportacion: (state, { payload = {} }) => {
      state.errores_importacion = payload;
      state.importar_loading = false;
    },
  },
});

export const kpiActions = kpiSlice.actions;
export const kpiReducer = kpiSlice.reducer;
