export const capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ObjectToString = (obj) => {
  let string = "";
  !!process.env.REACT_APP_DEBUG &&
    console.log("ObjectToString 0", !Array.isArray(obj));
  // if (!Array.isArray(obj)) return obj;
  // !!process.env.REACT_APP_DEBUG && console.log("ObjectToString 1");
  //
  Object.keys(obj).forEach((k) => (string += `${k}: ${obj[k]}`));
  !!process.env.REACT_APP_DEBUG && console.log("ObjectToString 2", string);
  return string;
};

export const ObjectToFormData = (item) => {
  let formData = new FormData();

  for (let key in item) {
    let value = item[key];
    if (value !== null && value !== undefined) formData.append(key, value);
  }

  return formData;
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length).replaceAll("\\054", ",");
    }
  }
  return "";
};

export const getUserFromCookie = () => {
  let user = {};
  user.nombre = getCookie("usuario_nombre").replaceAll('"', "");
  let colaborador_id = getCookie("colaborador_id").replaceAll('"', "");
  colaborador_id = !!colaborador_id ? parseInt(colaborador_id) : 0;
  user.colaborador_id = colaborador_id;
  user.foto_empleado = getCookie("foto_empleado").replaceAll('"', "");
  user.es_admin = parseInt(getCookie("es_admin")) === 1 ? true : false;
  user.es_admin_parcial =
    parseInt(getCookie("es_admin_parcial")) === 1 ? true : false;
  return user;
};

export const getToken = () => {
  let token = getCookie("token");
  if (!token) {
    token = getCookie("kento");
  }
  return token;
};

export const deleteCookie = (name, path, domain = "localhost") => {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
};

export const deleteUserFromCookie = (domain = "localhost") => {
  deleteCookie("usuario_nombre", "/", domain);
  deleteCookie("foto_empleado", "/", domain);
  deleteCookie("colaborador_id", "/", domain);
  deleteCookie("empresa_id", "/", domain);
  deleteCookie("usuario_nombre_completo", "/", domain);
  deleteCookie("es_admin", "/", domain);
  deleteCookie("es_admin_parcial", "/", domain);
};

export const formatNumber = (num) => {
  const formattedNum = num.toLocaleString("es-ES", {
    style: "decimal",
    minimumFractionDigits: Number.isInteger(num) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(num) ? 0 : 2,
    useGrouping: true,
  });
  //
  return formattedNum;
};

export const formatNumberOnTyping = (number) => {
  console.log("number init", number);
  number += "";
  //
  let sign = "";
  if (number[0] === "-") {
    sign = "-";
    number = number.slice(1);
  }
  //
  number = number.replaceAll(".", ""); //.
  let x = number.split(","); //,
  let x1 = x[0];
  let x2 = x.length > 1 ? "," + x[1] : ""; //,
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2"); //.
  }
  //
  return sign + x1 + x2;
};
